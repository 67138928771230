import { Component, Input, computed, effect, inject, input, output, signal } from '@angular/core';
import { ModalSingleComponent } from '@components/modal-single/modal-single.component';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { TaskTitleAvatarComponent } from 'src/app/pages/performance-review/performance-review-task-title-template/task-title-avatar/task-title-avatar.component';
import { StaffLinkDisplayComponent } from '@components/staff-link-display/staff-link-display.component';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';
import { Dictionary } from 'underscore';
import { IUIStreamlineIcon } from '@ui/streamline-icon/streamline-icon-interfaces';
import { TaskInfoCardComponent } from './task-info-card/task-info-card.component';
import { SuccessBannerComponent } from '@components/banners/success-banner/success-banner.component';
import { IPerformanceReviewAssignFull, ITablePerformanceReviewAssign, ITablePerformanceReviewAssignFull, PerformanceReviewService } from 'src/app/services/performance-review.service';
import { TaskManagerInputComponent } from './task-manager-input/task-manager-input.component';
import { map } from 'rxjs/operators';
import { toSignal } from '@angular/core/rxjs-interop';
import { ConfirmationModal } from 'src/app/shared/utils/base-class/confirmation-modal';
import { LoadingContainerComponent } from '@ui/loading-container/loading-container.component';
import { WarningBannerComponent } from 'src/app/pages/compliance/compliance-workroom/mark-complete-bulk/warning-banner/warning-banner.component';
import { AnalyticsPrService } from 'src/app/pages/performance-review/analytics-pr.service';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-task-modal',
  standalone: true,
  imports: [ModalSingleComponent, TitleCasePipe, TaskTitleAvatarComponent, StaffLinkDisplayComponent, CommonModule,
    UIStreamlineIconComponent, TaskInfoCardComponent, SuccessBannerComponent, TaskManagerInputComponent,
    LoadingContainerComponent, WarningBannerComponent],
  templateUrl: './task-modal.component.html',
  styleUrl: './task-modal.component.scss'
})
export class TaskModalComponent extends ConfirmationModal {
  // @Input() task: ITablePerformanceReviewAssign;
  @Input() taskID: string;
  @Input() context = 'my tasks';
  task = signal<ITablePerformanceReviewAssignFull>(null);
  performanceReviewService = inject(PerformanceReviewService);
  analyticsPrService = inject(AnalyticsPrService);

  taskUpdated = output();

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.performanceReviewService.fetchFullTaskByID(this.taskID).subscribe(t => {
      this.task.set(t);
      this.analyticsPrService.sendTrack('PR-task-viewed', this.analyticsPrService.getTaskInfo(t, this.context));
    })
  }

  commentSuccess(task: ITablePerformanceReviewAssignFull) {
    if (task.status === 'Completed' && this.task().status !== 'Completed') {
      this.analyticsPrService.sendTrack('PR-task-marked_completed', {
        ...this.analyticsPrService.getTaskInfo(this.task(), this.context),
        dateCompleted: dayjs().format('YYYY-MM-DD')
      });
    } 
    this.task.set(task);
    this.taskUpdated.emit();
  }

  remind() {
    this.performanceReviewService.remindModal(this.task(), this.context);
  }

  get completionNum() {
    if (this.task().response) {
      if (this.task().managerComment) {
        return 2
      }
      return 1
    }
    return 0;
  }

  get circle1Icon() {
    return this.task().response ? CIRCLE_ICON_DICT.done : CIRCLE_ICON_DICT.open

  }

  get circle2Icon() {
    if (!this.task().response) {
      return CIRCLE_ICON_DICT.unavailable;
    }
    return this.task().managerComment ? CIRCLE_ICON_DICT.done : CIRCLE_ICON_DICT.open

  }

  get item1Text() {
    return this.task().response ? 'Review Completed by the Reviewee' : 'Reviewee Haven’t Completed a Review'

  }

  get item2Text() {
    return 'Manager is required to comment on self-reflection response'

  }

}

export const CIRCLE_ICON_DICT: Dictionary<IUIStreamlineIcon> = {
  open: { name: 'circle-outline', theme: 'bold', color: 'success', size: 'sm' },
  done: {
    "name": "interface-validation-check-circle",
    "theme": "micro-bold",
    "color": "fill-success",
  },
  unavailable: { name: 'circle-outline', theme: 'bold', color: 'muted', size: 'sm' }
}