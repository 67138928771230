import { Component, OnInit } from '@angular/core';
import { FEATURES } from 'src/app/core/features.config';
import { REPORT_CARD_CONFIG } from './report-card-config';
import { REPORT_CAT_DISPLAY } from './reporting-subpage/reporting-subpage.component';
import { ReportCategoryCardComponent } from './report-category-card/report-category-card.component';
import { AvatarComponent } from '../../../shared/components/avatar/avatar.component';
import { OverviewCardComponent } from '../shared-overview/overview-card/overview-card.component';
import { CommonModule, NgFor } from '@angular/common';
import { SharedOverviewComponent } from '../shared-overview/shared-overview.component';
import { FeatureFlagDirective } from 'src/app/shared/directives/feature-flag.directive';
import { AnalyticsService, PAGE_CONTEXT_TYPES } from 'src/app/services/analytics.service';
import { OverviewShortcutsComponent } from './overview-shortcuts/overview-shortcuts.component';
import { GeneralCarouselComponent } from 'src/app/shared/carousel/general-carousel/general-carousel.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { takeUntil } from 'rxjs/operators';
import { WithDestroy } from 'src/app/shared/utils/mixins/withDestroy';
import ColourMap from '@ui/colour-token-map';

@Component({
  selector: 'app-overview-reporting',
  templateUrl: './overview-reporting.component.html',
  styleUrls: ['./overview-reporting.component.scss'],
  standalone: true,
  imports: [SharedOverviewComponent, NgFor, OverviewCardComponent, AvatarComponent, ReportCategoryCardComponent, FeatureFlagDirective, OverviewShortcutsComponent, GeneralCarouselComponent, CommonModule]
})
export class OverviewReportingComponent extends WithDestroy() implements OnInit {
  items = [
    // REPORT_CARD_CONFIG.newRequirementStatus,
    REPORT_CARD_CONFIG.allRequirementStatus,
    // REPORT_CARD_CONFIG.complianceCompletions,
    // REPORT_CARD_CONFIG.allRequirementCompletions,
    REPORT_CARD_CONFIG.allLearning,
    REPORT_CARD_CONFIG.standards,
    REPORT_CARD_CONFIG.passport
  ];

  categories = [
    {
      title: REPORT_CAT_DISPLAY.requirementStatus,
      description: 'Report on the requirement records for your organisation.',
      featureFlag: FEATURES.MT,
      route: this.getCategoryRoute(REPORT_CATEGORY.requirementStatus),
      reportCategory: REPORT_CATEGORY.requirementStatus,
      slIcon: { name: 'notes-paper-approve', theme: 'bold'},
      bannerColor: ColourMap.get('m-sky-dark'),
      popoverData: {
        displayText: 'What is a Requirement Record?',
        popoverTitle: 'Requirement Record',
        popoverText: 'A requirement record is created each time a staff member is assigned a requirement.'
      }
    },
    // {
    //   title: REPORT_CAT_DISPLAY.completionRecords,
    //   featureFlag: FEATURES.MT,
    //   route: this.getCategoryRoute(REPORT_CATEGORY.completionRecords),
    //   reportCategory: REPORT_CATEGORY.completionRecords,
    //   slIcon: {name: 'task-list-to-do', theme: 'bold', color: 'fill-danger'}

    // },
    {
      title: REPORT_CAT_DISPLAY.learningRecords,
      description: 'Report on all records of learning completed in your organisation.',
      // featureFlag: FEATURES.MT,
      route: this.getCategoryRoute(REPORT_CATEGORY.learningRecords),
      reportCategory: REPORT_CATEGORY.learningRecords,
      slIcon: { name: 'task-list-to-do', theme: 'bold'},
      bannerColor: ColourMap.get('yellow-700'),
      popoverData: {
        displayText: 'What is a Learning Record?',
        popoverTitle: 'Learning Record',
        popoverText: 'A learning record is created when a user completes learning, a user documents learning, or a manager adds an internal learning record.  '
      }
    },
    {
      title: REPORT_CAT_DISPLAY.extensions,
      description: 'Report on requirements that have been extended.',
      featureFlag: FEATURES.MT,
      route: this.getCategoryRoute(REPORT_CATEGORY.extensions),
      reportCategory: REPORT_CATEGORY.extensions,
      slIcon: { name: 'calendar-add-1', theme: 'bold'},
      bannerColor: ColourMap.get('neutral-800'),
    },
    {
      title: REPORT_CAT_DISPLAY.evaluateLearning,
      description: 'Report on user’s feedback about the learning in your organisation.',
      route: this.getCategoryRoute(REPORT_CATEGORY.evaluateLearning),
      reportCategory: REPORT_CATEGORY.evaluateLearning,
      slIcon: { name: 'rating-star-alternate', theme: 'bold'},
      bannerColor: ColourMap.get('m-lavender-default'),
    },
    // {
    //   title: REPORT_CAT_DISPLAY.stakeholderReports,
    //   featureFlag: FEATURES.MT,
    //   route: this.getCategoryRoute(REPORT_CATEGORY.stakeholderReports),
    //   reportCategory: REPORT_CATEGORY.stakeholderReports,
    //   isAdvanced: true,
    //   slIcon: {name: 'pie-line-graph', theme: 'bold', color: 'fill-danger'}
    // },
    {
      title: REPORT_CAT_DISPLAY.ausmedPassport,
      description: 'Report on user’s feedback about the learning in your organisation.',
      route: this.getCategoryRoute(REPORT_CATEGORY.ausmedPassport),
      reportCategory: REPORT_CATEGORY.ausmedPassport,
      slIcon: { name: 'ausmed-passport', theme: 'bold'},
      bannerColor: ColourMap.get('blue-600'),
    },
    {
      title: REPORT_CAT_DISPLAY.payroll,
      description: 'Use this report to see when learning was completed as well as learning duration to assist with Payroll purposes.',
      route: this.getCategoryRoute(REPORT_CATEGORY.payroll),
      reportCategory: REPORT_CATEGORY.payroll,
      slIcon: { name: 'currency-dollar-circle', theme: 'bold'},
      bannerColor: ColourMap.get('pink-600'),
      featureFlag: FEATURES.MT,
    },

  ]

  advancedReports = [REPORT_CARD_CONFIG.audit, REPORT_CARD_CONFIG.team, REPORT_CARD_CONFIG.board
  ]
  hideSwiper: boolean;

  getCategoryRoute(cat: REPORT_CATEGORY) {
    const categoryLinkMap = {
      [REPORT_CATEGORY.requirementStatus]: 'requirement-records',
      [REPORT_CATEGORY.completionRecords]: 'completion-records',
      [REPORT_CATEGORY.extensions]: 'extensions',
      [REPORT_CATEGORY.learningRecords]: 'learning-records',
      [REPORT_CATEGORY.evaluateLearning]: 'learning-evaluate',
      [REPORT_CATEGORY.stakeholderReports]: 'stakeholder-reports',
      [REPORT_CATEGORY.ausmedPassport]: 'ausmed-passport',
      [REPORT_CATEGORY.payroll]: 'payroll',
    }
    return [`/pages/reporting/overview/${categoryLinkMap[cat]}`]
  }

  constructor(
    private analytics: AnalyticsService,
    private breakpointObserver: BreakpointObserver
  ){super()}

  ngOnInit(): void {
    this.analytics.track('Z-page-loaded', {
      context: PAGE_CONTEXT_TYPES.report,
      loadTime: 0,
    });

    this.breakpointObserver.observe([
      "(max-width: 1300px)"
    ]).pipe(takeUntil(this.destroy$))
    .subscribe(r => {
      console.log(r.matches)
      if (r.matches) {
        this.hideSwiper = true;
      }
      else {
        this.hideSwiper = false
      }
    })

  }
}

export enum REPORT_CATEGORY {
  requirementStatus = 'requirementStatus',
  completionRecords = 'completionRecords',
  extensions = 'extensions',
  learningRecords = 'learningRecords',
  evaluateLearning = 'evaluateLearning',
  stakeholderReports = 'stakeholderReports',
  ausmedPassport = 'ausmedPassport',
  payroll = 'payroll'
}
