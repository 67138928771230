import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { WithDestroy } from 'src/app/shared/utils/mixins/withDestroy';
import { REPORT_CATEGORY } from '../overview-reporting.component';
import { REPORT_CARD_CONFIG } from '../report-card-config';
import { IUIStreamlineIcon } from '@ui/streamline-icon/streamline-icon-interfaces';
import { AdvancedCardComponent } from '../advanced-card/advanced-card.component';
import { AvatarComponent } from '../../../../shared/components/avatar/avatar.component';
import { OverviewCardComponent } from '../../shared-overview/overview-card/overview-card.component';
import { NgFor, NgIf } from '@angular/common';

import { BackButtonComponent } from '../../../../shared/components/back-button/back-button.component';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';

@Component({
  selector: 'app-reporting-subpage',
  templateUrl: './reporting-subpage.component.html',
  styleUrls: ['./reporting-subpage.component.scss'],
  standalone: true,
  imports: [BackButtonComponent, NgFor, NgIf, OverviewCardComponent, AvatarComponent, AdvancedCardComponent, UIStreamlineIconComponent]
})
export class ReportingSubpageComponent extends WithDestroy() {
  items: any[]
  cardGroups: {items}[];
  reportCategory: REPORT_CATEGORY;

  REPORT_CATEGORY = REPORT_CATEGORY;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
    super()
  }


  ngOnInit() {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      console.log("params['category']", params['category'])
      this.reportCategory = LINK_CATEGORY_MAP[params['category']];
      const items = CATEGORY_ITEMS_DICT[this.reportCategory];
      if (!items) {
        this.router.navigate(['pages/reporting/overview/overview-page']);
      }
      // if no  grouping, just create 1 group
      this.cardGroups = items[0].items ? items : [{items: items}]
    });
  }

  get title() {
    return REPORT_CAT_DISPLAY[this.reportCategory] || ''
  }

  get titleIcon() {
    return REPORT_CAT_ICON[this.reportCategory];
  }
}

export const CATEGORY_ITEMS_DICT: Record<REPORT_CATEGORY, any[]> = {
  requirementStatus: [
    {
      groupTitle: 'Requirement Assignments',
      items: [
        REPORT_CARD_CONFIG.allRequirementStatus,
        REPORT_CARD_CONFIG.complianceStatus,
        REPORT_CARD_CONFIG.mandatoryStatus,
        REPORT_CARD_CONFIG.allOptionalStatus,
      ]
    },
    {
      groupTitle: 'Requirement State',
      // groupDescription: '',
      items: [REPORT_CARD_CONFIG.newRequirementStatus]
    },
  ],
  extensions: [REPORT_CARD_CONFIG.extended],
  completionRecords: [
    // REPORT_CARD_CONFIG.complianceCompletions,
    // REPORT_CARD_CONFIG.allRequirementCompletions,
  ],
  evaluateLearning: [REPORT_CARD_CONFIG.suggested, REPORT_CARD_CONFIG.feedback],
  learningRecords: [REPORT_CARD_CONFIG.allLearning, REPORT_CARD_CONFIG.standards, REPORT_CARD_CONFIG.time, REPORT_CARD_CONFIG.learningAnalytics],
  stakeholderReports: [REPORT_CARD_CONFIG.audit, REPORT_CARD_CONFIG.team, REPORT_CARD_CONFIG.board],
  ausmedPassport: [REPORT_CARD_CONFIG.passport, REPORT_CARD_CONFIG.passportUser],
  payroll: [REPORT_CARD_CONFIG.payroll]
}

export const REPORT_CAT_DISPLAY: Record<REPORT_CATEGORY, string> = {
  requirementStatus: 'Requirement Records',
  extensions: 'Extensions',
  completionRecords: 'Completion Records',
  evaluateLearning: 'Learning Evaluation',
  learningRecords: 'Learning Records',
  stakeholderReports: 'Stakeholder Reports',
  ausmedPassport: 'Ausmed Passport',
  payroll: 'Payroll',
}

export const REPORT_CAT_ICON: Record<REPORT_CATEGORY, IUIStreamlineIcon> = {
  stakeholderReports: { name: 'pie-line-graph', theme: 'bold' },
  completionRecords: { name: 'task-list-to-do', theme: 'bold' },
  requirementStatus: { name: 'graph-stats-circle-alternate', theme: 'bold' },
  extensions: { name: 'calendar-add-1', theme: 'bold' },
  evaluateLearning: { name: 'rating-star-alternate', theme: 'bold' },
  learningRecords: { name: 'study-book', theme: 'bold' },
  ausmedPassport: { name: 'ausmed-passport', theme: 'bold' },
  payroll: { name: 'file-copyright-dollar', theme: 'bold' },
}

export const LINK_CATEGORY_MAP = {
  'requirement-records': 'requirementStatus',
  'completion-records': 'completionRecords',
  'extensions': 'extensions',
  'learning-records': 'learningRecords',
  'learning-evaluate': 'evaluateLearning',
  'stakeholder-reports': 'stakeholderReports',
  'ausmed-passport': 'ausmedPassport',
  'payroll': 'payroll'
}
