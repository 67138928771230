import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuService } from '../../../services/menu.service';
import { NgbModal, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem } from '@ng-bootstrap/ng-bootstrap';
import { SharedEditTableColumnModalComponent } from '../../popups/shared-edit-table-column-modal/shared-edit-table-column-modal.component';
import { LocalTableUniqKey } from '../../popups/shared-edit-table-column-modal/localTableUniqKey';
import { ICacheTableSettings } from '../../popups/shared-edit-table-column-modal/LocalEditedColumnsByKey';
import { removeEmptyFields } from '../../utils/remove-empty-fields';
import { applyCacheSettings } from '@components/smart-table/smart-table.component';
import { BroadcastService } from 'src/app/services/broadcast.service';

import { UISpinnerComponent } from '../../../ui/ui-spinner/ui-spinner.component';
import { CommonModule, NgIf } from '@angular/common';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';

export interface ITableActionsConfig {
  toggleFilters?: boolean;
  editColumns?: boolean;
  expandTable?: boolean;
  downloadTable?: boolean;
}

@Component({
  selector: 'app-shared-table-actions',
  templateUrl: './shared-table-actions.component.html',
  styleUrls: ['./shared-table-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgbDropdown,
    NgbDropdownToggle,
    NgIf,
    UISpinnerComponent,
    NgbDropdownMenu,
    NgbDropdownItem,
    UIStreamlineIconComponent,
    CommonModule
  ],
})

export class SharedTableActionsComponent implements OnInit {
  @Input() actionsConfig: ITableActionsConfig = {
    expandTable: true,
    toggleFilters: true,
    editColumns: true,
    downloadTable: true,
  };
  // refresh flag was introduced to refresh the table correctly; dynamically change settings will not refresh the ng2-smart-table
  @Input() refreshFlag;
  @Output() refreshFlagChange = new EventEmitter<boolean>();
  @Input() loading = false;
  @Input() disable = false;
  @Input() tableSettings: any;
  @Input() hideSortColumn: false;
  @Input() withWrapper = false;
  @Output() download: EventEmitter<any> = new EventEmitter<any>();
  // @Note: When decorate out of the context, it will not be functional
  // @Output() tableSettingsChange: EventEmitter<object> = new EventEmitter<object>();
  @Output() editColumns: EventEmitter<object> = new EventEmitter<object>();
  @Output() toggleFilters: EventEmitter<object> = new EventEmitter<object>();

  constructor(private modalService: NgbModal,
    private menuService: MenuService,
    private broadcastService: BroadcastService) {
  }

  ngOnInit(): void {
  }

  editColumn() {
    if (!this.tableSettings) {
      throw new TypeError('You forgot to pass in the table settings!');
    }
    if (!this.tableSettings?.tableUniqKey) {
      throw new TypeError('You forgot to setup table unique key for the table!');
    }
    const editColumnModal = this.modalService.open(SharedEditTableColumnModalComponent);
    editColumnModal.componentInstance.tableColumnsConfig = this.tableSettings.columns;
    editColumnModal.componentInstance.localCacheKey = this.tableSettings.tableUniqKey;
    editColumnModal.componentInstance.hideSortColumn = this.hideSortColumn;
    editColumnModal.result.then(result => {
      if (result === 'refresh') {
        // this.tableSettings = {...this.tableSettings};
        // this.tableSettingsChange.emit(this.tableSettings);
        this.editColumns.emit({ ...this.tableSettings });
      }
    });
  }

  downloadCSV() {
    // TODO: sync tables can be integrated with download functions
    const currentSettings = (applyCacheSettings(this.tableSettings, this.tableSettings.tableUniqKey));
    this.download.emit(currentSettings);
    if (currentSettings.tableUniqKey) {
      this.broadcastService.broadcast('table-download-click', {tableKey: currentSettings.tableUniqKey})
    }
  }

  expand() {
    this.menuService.toggleNav();
  }

  onToggleFiltersClick() {
    const { tableUniqKey } = this.tableSettings;
    if (!tableUniqKey) {
      throw new Error('Please provide tableUniqKey in the settings');
    }
    if (!Object.values(LocalTableUniqKey).includes(tableUniqKey)) {
      throw new TypeError('The tableUniqKey is not declared in the LocalTableUniqKey Enum');
    }
    const parsedSettings = JSON.parse(localStorage.getItem(tableUniqKey)) as ICacheTableSettings | [];
    let columns;
    if (parsedSettings) {
      if (Array.isArray(parsedSettings)) {
        columns = parsedSettings;
      } else {
        columns = parsedSettings.columns;
      }
    }
    const hideSubHeader = (parsedSettings as any)?.hideSubHeader || this.tableSettings.hideSubHeader || false;

    const newCacheTableSettings = { columns, hideSubHeader: !hideSubHeader } as ICacheTableSettings;
    removeEmptyFields(newCacheTableSettings);
    localStorage.setItem(tableUniqKey, JSON.stringify(newCacheTableSettings));
    this.broadcastService.broadcast('column-settings-updated', { tableKey: tableUniqKey });
  }

  get showOrHideFilterDisplay(): 'Show' | 'Hide' {
    if (this.tableSettings?.tableUniqKey) {
      const parsedSettings = JSON.parse(localStorage.getItem(this.tableSettings.tableUniqKey));
      if (!parsedSettings) {
        return 'Hide';
      } else {
        return parsedSettings.hideSubHeader === true ? 'Show' : 'Hide';
      }
    }
    return 'Hide';
  }

  get displayExpandTableText(): 'Expand Table' | 'Collapse Table' {
    return this.menuService.open ? 'Expand Table' : 'Collapse Table';
  }
}
