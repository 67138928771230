<ui-modal-single>
    <ng-template #headingSlot>
        <div class="d-flex gap-2 align-items-center">
            <!-- <app-task-title-avatar [taskType]="task()?.taskTemplate?.taskType"></app-task-title-avatar> -->
            <h3>{{task()?.taskTemplate?.title}}</h3>
        </div>
    </ng-template>

    <ng-template #contentSlot>
        <app-loading-container [isLoaded]="task()">
        <div class="d-flex gap-4 pb-4">
                @if(task()) {
                    <div class="children-horizontal-gap-8 flex-grow-1">
                        <div>
                            <app-task-info-card [task]="task()"></app-task-info-card>
                        </div>

                        <!-- @if(task().status === 'Completed') {
                            <div>
                                <app-success-banner [copy]="'Comments are successfully saved and this task is complete'"></app-success-banner>
                            </div>
                        } -->

                        <!-- <div>
                            <p class="f-small fw-medium text-neutral-700 mb-1">Reviewee</p>
                            <staff-link-display [value]="task().user" avatarSize="md"></staff-link-display>
                        </div> -->

                        <!-- @if(task().taskTemplate?.completionType === 'Both') {
                            <div class="card children-horizontal-gap-4 p-4">
                                <p class="text-neutral-700 fw-semibold f-small">{{completionNum}}/2 Complete</p>
                                <div class="d-flex gap-4 align-items-center">
                                    <ui-streamline-icon
                                    [slIcon]="circle1Icon"
                                    ></ui-streamline-icon>
                                    <p class="fw-semibold">{{item1Text}}</p>
                                </div>
                                <div class="d-flex gap-4 align-items-center">
                                    <ui-streamline-icon
                                    [slIcon]="circle2Icon"
                                    ></ui-streamline-icon>
                                    @if (task().response) {
                                        <p class="fw-semibold">{{item2Text}}</p>
                                    } @else {
                                        <div>
                                            <p class="fw-semibold text-neutral-700 mb-2">Manager is required to comment on self-reflection response</p>
                                            <p class="f-small text-neutral-700">Reviewee must send their response for manager to comment</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        } -->
                        @if(task().taskTemplate?.completionType === 'Staff' && !task().response) {
                            <!-- <p class="fw-semibold">{{task().user.fullName}} has not completed this self assessment</p> -->
                            <div class="d-flex flex-column justify-content-between align-items-end" [style.min-height]="'50vh'">
                                <div class="w-100">
                                    <app-warning-banner [copy]="task().user?.fullName + ' has not completed this self assessment'" color="blue">
                                        <button (click)="remind()" class="btn btn-outline-light">
                                            <ui-streamline-icon [slIcon]="{name: 'alarm-bell', theme: 'bold', svgClassIn: 'text-warning'}" class="me-2"></ui-streamline-icon>
                                            Send Reminder</button>                                    
                                    </app-warning-banner>

                                </div>
                                <button (click)="dismiss()" class="btn btn-outline-light">Close</button>

                                </div>
                        } @else {
                          <!-- <div [style.max-height]="'50vh'" class="p-1 overflow-y-scroll"> -->
                              <app-task-manager-input [task]="task()" (taskStatusChanged)="commentSuccess($event)" [showCloseButton]="true" [maxHeight]="'50vh'"></app-task-manager-input>
                          <!-- </div> -->
                        }
                    </div>

                }

            </div>
        </app-loading-container>
    </ng-template>
</ui-modal-single>
