import { inject } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

export class ConfirmationModal {
    activeModal = inject(NgbActiveModal);
    toaster = inject(ToastrService);

    isLoading = false;

    confirmSuccess(message, closePayload: any=true) {
        this.toaster.success(message);
        this.activeModal.close(closePayload);
    }

    dismiss() {
        this.activeModal.dismiss();
    }

    error(msg: string) {
        this.toaster.error(msg);
        this.isLoading = false;
    }

}