import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, } from 'rxjs';
import { catchError, share, tap, map, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ErrorHandlingService } from './error-handling.service';
import { BroadcastService } from './broadcast.service';
import { intersection, keyBy, Dictionary, cloneDeep } from 'lodash';
import { IJobRoleTaken, ITeam, getColJobRoleType } from './group.service';
import { isBetweenDate } from '../shared/utils/is-between-date';
import { omit, pluck } from 'underscore';
import { LOCATION_TYPE_DISPLAY } from '../pages/manage-location/admin-location-types';
import { ALL_TAB_ON_USER_MANAGEMENT_TABLE_USER_LIST, isUserIncluded } from '../shared/utils/specialFeaturesUserList/user-list-by-features';
import { SHOW_FUTURE_JOB_ROLES_LIST, orgInTheFeatureOrgList } from '../shared/utils/orgList/org-list-by-feature';
import * as dayjs from 'dayjs';
import { IHierarchy, getHierarchyDict } from '../pages/admin/hierarchy/hierarchy.service';
import { spreadUserTeams } from './service-utils/user-service-utils';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _isManager = false;
  private _managedTeams = [];
  user: BehaviorSubject<any> = new BehaviorSubject({});
  private _pendingUser$: Observable<any>;
  allRawOrgUsers: IOrgUser[];

  orgUser: BehaviorSubject<IOrgUser> = new BehaviorSubject(null);
  private _pendingOrgUser$: Observable<IOrgUser>;

  userPlanSummaries: BehaviorSubject<any[]> = new BehaviorSubject([]);
  private _pendingUserPlanSummaries$: Observable<any[]>;
  allTeamManagersOfManagedTeams: IOrgUser[];

  lastDocumented = new BehaviorSubject([]);
  private _pendingLastDocumented$: Observable<any[]>;
  plainUser = {};
  plainOrgUser: IOrgUser;
  managedOrgUserDictionaryByOrgUserID: Dictionary<IOrgUser>;
  managedOrgUserDictionaryByUserID: Dictionary<IOrgUser>;
  allOrgUserDictionaryByUserID: Dictionary<IOrgUser>;
  managedOrgUserDictionaryByOrgEmail: Dictionary<IOrgUser>;
  managedOrgUserDictionaryByStaffID: Dictionary<IOrgUser>;
  managedOrgUserDictionaryByEmail: Dictionary<IOrgUser>;
  userList: IOrgUser[]; // managed users only

  classifiedOrgUsers = new BehaviorSubject({
    applied: [],
    invited: [],
    // oldInvited: [],
    pending: [],
    // oldPending: [],
    active: [],
    rejected: [],
    // oldRejected: [],
    inActive: [],
    // oldInactive: [],
    refused: [],
    valid: [],
    hasUserIDs: [],
    all: [],
  });
  private _pendingOrgUsers$: Observable<IClassifiedOrgUsers>;

  // Set up users email dict for duplicate email lookup;
  usersEmailDict = new Map();
  managedOrgUserByID: Dictionary<IOrgUser> = {};

  deletedUser = '';
  managedOrgHierarchyDict: Dictionary<IHierarchy>; // use manager's orgUserID for key
  globalHierarchyDict: Dictionary<IHierarchy>; // use manager's orgUserID for key

  constructor(
    private httpClient: HttpClient,
    private broadcastService: BroadcastService,
    private errorHandlingService: ErrorHandlingService,
  ) {
    this.broadcastService.on('logout').subscribe(() => {
      this.user.next({});
      this.orgUser.next(null);
      this.plainOrgUser = null;
      this.plainUser = {};
      this.classifiedOrgUsers.next({
        applied: [],
        invited: [],
        // oldInvited: [],
        pending: [],
        // oldPending: [],
        active: [],
        rejected: [],
        // oldRejected: [],
        inActive: [],
        // oldInactive: [],
        refused: [],
        valid: [],
        hasUserIDs: [],
        all: []
      });
      this.usersEmailDict.clear();
    });
  }

  public setUser(user): void {
    this.user.next(user);
  }

  get isManager(): boolean {
    return this._isManager;
  }

  set isManager(value: boolean) {
    this._isManager = value;
  }

  // managedAllTeams (includes child of managed teams/facilities)
  get managedTeams(): string[] {
    return this._managedTeams;
  }

  set managedTeams(value: string[]) {
    this._managedTeams = value;
  }

  managedTeamModels: ITeam[]; // included all managed teams

  public setIsManger(isManager): void {
    this.isManager = isManager;
  }

  public fetchCachedUser(refresh?: boolean): Observable<any> {
    const user = this.user.value;
    if (this._pendingUser$) {
      return this._pendingUser$;
    } else if (user && user.userID && !refresh) { // check for userID because we don't want {}
      return of(user);
    }
    return this._pendingUser$ = this.fetchUser().pipe(
      share(),
      tap(() => delete this._pendingUser$),
      catchError((e) => {
        delete this._pendingUser$;
        return this.errorHandlingService.handleHttpError(e);
      }),
    );
  }

  public fetchUser(): Observable<any> {
    return this.httpClient.get(environment.accountServiceEndpoint + '/user')
      .pipe(tap((user: any) => {
        if (!environment.production) {
          console.log('user', user);
        }
        this.plainUser = user;

        // todo event tracking identify
        this.user.next(user);
      }));
  }

  public getUser(): Observable<any> {
    return this.user.asObservable();
  }

  public setOrgUser(orgUser: IOrgUser): void {
    this.orgUser.next(this._spreadOrgUserBasedOnInvitationAndDetails(orgUser));
    this.plainOrgUser = orgUser;
    this.isManager = orgUser.isManager;
  }

  public getOrgUser(): Observable<IOrgUser> {
    return this.orgUser.asObservable();
  }

  public getOrgUserComplete() {
    return spreadUserTeams(this._spreadOrgUserBasedOnInvitationAndDetails(cloneDeep(this.plainOrgUser)))
  }

  public getOrgUserWorkStartDate(orgUser: IOrgUser) {
    return orgUser.profile?.startDate || orgUser.workStartDate || '-';
  }

  public getPermissionTypeByUser(user: IOrgUser): USER_PERMISSION_TYPE {
    const permissionDisplayText = user.isManager ? USER_PERMISSION_TYPE.organisationManager : user.managedTeams?.length ? USER_PERMISSION_TYPE.teamManager : USER_PERMISSION_TYPE.learner;
    return permissionDisplayText;
  }

  public fetchCachedOrgUser(orgID: string, userID: string, refresh?: boolean): Observable<any> {
    const orgUser = this.orgUser.value;
    if (this._pendingOrgUser$) {
      return this._pendingOrgUser$;
    } else if (orgUser && orgUser.orgUserID && !refresh) { // check for userID because we don't want {}
      return of(orgUser);
    }
    return this._pendingOrgUser$ = this.fetchOrgUser(orgID, userID).pipe(
      share(),
      tap(() => delete this._pendingOrgUser$),
      catchError((e) => {
        delete this._pendingOrgUser$;
        return this.errorHandlingService.handleHttpError(e);
      }),
    );
  }

  public fetchOrgUser(orgID: string, userID: string): Observable<IOrgUser> {
    return this.httpClient
      .get(environment.accountServiceEndpoint + '/orgs/' + orgID + '/users/' + userID)
      .pipe(
        tap((orgUser: any) => {
          this.orgUser.next(orgUser);
        }),
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  // TODO: filter by userDetail/application/invitation
  fetchOrgUsers(orgId: string): Observable<IClassifiedOrgUsers> {
    // console.log('fetching org users');
    const url = `${environment.accountServiceEndpoint}/orgs/${orgId}/orgUsers`;
    return this.httpClient.get<IOrgUser[]>(url).pipe(
      tap((allOrgUsers) => {
        this.allRawOrgUsers = [...allOrgUsers];
        // Set up users email dict for duplicate email lookup;
        this.allRawOrgUsers.forEach((user) => {
          this.usersEmailDict.set(user.userDetail?.email ? user.userDetail.email.toLocaleLowerCase() : user.orgEmail?.toLocaleLowerCase(), user);
        });
        this.allOrgUserDictionaryByUserID = keyBy(allOrgUsers, 'userID');
        if (this.isManager) {
          this.allTeamManagersOfManagedTeams = allOrgUsers.filter(user => (user.hasOwnProperty('userDetail') || user.hasOwnProperty('invitation') || !user.hasOwnProperty('application')))
            .map(ou => this._spreadOrgUserBasedOnInvitationAndDetails(ou));
        } else {
          this.allTeamManagersOfManagedTeams = allOrgUsers.filter(user => (user.hasOwnProperty('userDetail') || user.hasOwnProperty('invitation') || !user.hasOwnProperty('application')))
            .filter(({ managedTeams: userManagedTeams = [] }) => intersection(userManagedTeams, this.managedTeams).length).map(ou => this._spreadOrgUserBasedOnInvitationAndDetails(ou));
        }
      }),
      // separate user's teams and facilities
      map(allOrgUsers => allOrgUsers.map(u => spreadUserTeams(u))),
      tap(globalOrgUsers => this.globalHierarchyDict = getHierarchyDict(globalOrgUsers)),      
      map((allOrgUsers) => this.isManager ? allOrgUsers :
        allOrgUsers.filter(({ allTeams }) => intersection(allTeams, this.managedTeams).length)
      ),
      map((orgUsers: IOrgUser[]) => {
        /*
        ** team managers can only manage team member,
        ** org managers can manage all staff
        */
        if (this.isManager) {
          orgUsers.forEach(u => {
            u['isMember'] = true;
          });
        } else if (this.managedTeams && this.managedTeams.length > 0) {
          orgUsers.forEach(u => {
            const allTeams = u.allTeams;
            if (allTeams.length > 0 && (intersection(this.managedTeams, allTeams).length) > 0) {
              u['isMember'] = true;
            }
          });
        }
        orgUsers = orgUsers.filter(user => (user.hasOwnProperty('userDetail') || user.hasOwnProperty('invitation') || !user.hasOwnProperty('application')));
        const extendedOrgUsers: any[] = orgUsers.map(orgUser => {
          const extendedUser = this._spreadOrgUserBasedOnInvitationAndDetails(orgUser);
          // Set up users email dict for duplicate email lookup;
          this.usersEmailDict.set(extendedUser.userDetail && extendedUser.userDetail.email ? extendedUser.userDetail.email.toLocaleLowerCase() : extendedUser.orgEmail?.toLocaleLowerCase(), extendedUser);
          return extendedUser;
        });

        this.managedOrgUserDictionaryByOrgUserID = keyBy(extendedOrgUsers, 'orgUserID');
        this.managedOrgUserDictionaryByUserID = keyBy(extendedOrgUsers, 'userID');
        this.managedOrgUserDictionaryByStaffID = keyBy(extendedOrgUsers, 'staffID');
        this.managedOrgUserDictionaryByOrgEmail = keyBy(extendedOrgUsers, 'orgEmail');
        this.managedOrgUserDictionaryByEmail = keyBy(extendedOrgUsers, 'email');
        this.managedOrgHierarchyDict = getHierarchyDict(extendedOrgUsers);
        this.userList = extendedOrgUsers;


        const classifiedUsers = {
          invited: extendedOrgUsers.filter(u => u.status === 'Invited'),
          pending: extendedOrgUsers.filter(u => u.status === 'Pending'),
          rejected: extendedOrgUsers.filter(u => u.status === 'Rejected'),
          valid: extendedOrgUsers.filter(u => u.status !== 'In-active' && u.status !== 'Rejected'),
          // not sure
          inActive: extendedOrgUsers.filter(u => u.status === 'In-active'),
          active: extendedOrgUsers.filter(u => u.status === 'Active' && !!u.userID && !!u.userDetail),
          applied: extendedOrgUsers.filter(u => u.status === 'Applied' && !u.userID && !!u.application),
          refused: extendedOrgUsers.filter(u => u.status === 'Refused' && !u.userID && !!u.application),
          hasUserIDs: extendedOrgUsers.filter(u => !!u.userID && !!u.userDetail),
          all: extendedOrgUsers,
        };
        this.classifiedOrgUsers.next(classifiedUsers);
        return classifiedUsers;
      }),
      catchError(this.errorHandlingService.handleHttpError),
    );
  }


  _spreadOrgUserBasedOnInvitationAndDetails(orgUser: IOrgUser) {
    let extendedUser = orgUser;

    orgUser.teams = orgUser.teams || [];
    orgUser.managedTeams = orgUser.managedTeams || [];

    if (orgUser.hasOwnProperty('userDetail')) {
      extendedUser = { ...extendedUser, ...orgUser.userDetail };
    }
    if (orgUser.hasOwnProperty('application')) {
      extendedUser = { ...extendedUser, ...orgUser.application };
    }
    /*
    ** user might be with 'invitation' and 'userDetail' in the same time,
    ** (eg. new Rejected) in this case,
    ** duplicate properties such as firstname will be overriten by invitatinon info
    */
    // some active users may have invitation eg IRTLMS
    if (orgUser.hasOwnProperty('invitation') && (isUserIncluded(ALL_TAB_ON_USER_MANAGEMENT_TABLE_USER_LIST) || orgUser.status !== 'Active')) {
      extendedUser = { ...extendedUser, ...omit(orgUser.invitation, 'startDate') };
      if (orgUser.invitation.sentDate) {
        extendedUser['sentDate'] = orgUser.invitation.sentDate;
      }

      extendedUser['work'] = {
        position: orgUser.invitation.position,
        workType: orgUser.invitation.workType,
        department: orgUser.invitation.department,
        startDate: orgUser.invitation.startDate,
      };
      extendedUser['fullName'] = `${orgUser.invitation.firstName} ${orgUser.invitation.lastName}`;
      extendedUser['lastReminded'] = orgUser.invitation.sentDate;
    }

    if (orgUser.hasOwnProperty('periods')) {
      const periodSentDate = pluck(orgUser.periods, 'firstInvitedDate').reduce((p, c) => {
        if (p > c) {
          return p; // get the latest period invite
        }
        return c;
      });
      if (periodSentDate) {
        extendedUser['sentDate'] = periodSentDate;
      }
      extendedUser['lastReminded'] = extendedUser['lastReminded'] ||
        ((orgUser.periods?.length && orgUser.periods[0].invitedDatetimes?.length) ? orgUser.periods[0].invitedDatetimes.slice(-1)[0] : '');
    }

    // move work info out
    if (extendedUser.hasOwnProperty('work')) {
      extendedUser['department'] = extendedUser.work?.department;
      extendedUser['position'] = extendedUser.work?.position;
      extendedUser['workStartDate'] = extendedUser.profile?.startDate || extendedUser.work?.startDate;
      extendedUser['workplace'] = extendedUser.work?.workplace;
    } else {
      extendedUser['workStartDate'] = extendedUser.profile?.startDate;
      extendedUser.work = {};
    }

    // propagate activeJobRoleTakens
    this.setActiveJobRoleTakens(extendedUser);
    // just For IRTLMS
    if(orgInTheFeatureOrgList(SHOW_FUTURE_JOB_ROLES_LIST)){
      this.setFutureJobRoleTakens(extendedUser);
    }

    // internalID from profile
    extendedUser.internalID = extendedUser.internalID || extendedUser?.profile?.internalID;

    return extendedUser;
  }

  getOrgUsers(): Observable<any> {
    return this.classifiedOrgUsers.asObservable();
  }

  getOrgUsersMetrics(): Observable<{ activeUsers: number, notActiveUsers: number }> {
    return this.classifiedOrgUsers.asObservable()
      .pipe(
        map((classified: IClassifiedOrgUsers) => {
          return {
            activeUsers: classified.active.length,
            notActiveUsers: classified.invited.length + classified.pending.length + classified.applied.length
              + classified.rejected.length + classified.refused.length
          };
        }),
      );
  }

  public getOrgUserWorkType(user: IOrgUser): string {
    const workType = user.profile?.workType || user.work?.workType || user.invitation?.workType || '-';
    return workType;
  }

  public fetchCachedOrgUsers(orgId: string = localStorage.getItem('orgID'), refresh?: boolean): Observable<IClassifiedOrgUsers> {
    const orgUsers = this.classifiedOrgUsers.value;
    if (this._pendingOrgUsers$) {
      return this._pendingOrgUsers$;
    } else if (orgUsers && !refresh) {
      // return of(orgUsers)
      return this.classifiedOrgUsers.asObservable();
    }
    const orgUsers$ = this.fetchOrgUsers(orgId);
    return this._pendingOrgUsers$ = orgUsers$.pipe(
      share(),
      tap(() => delete this._pendingOrgUsers$),
      catchError((e) => {
        delete this._pendingOrgUsers$;
        return this.errorHandlingService.handleHttpError(e);
      }),
    );
  }

  createOrgInvitation(orgId, orgUser): Observable<IOrgUser> {
    return this.httpClient
      .post<IOrgUser>(`${environment.accountServiceEndpoint}/orgs/${orgId}/invitations`, orgUser)
      .pipe(catchError(this.errorHandlingService.handleHttpError));
  }

  deleteOrgInvitation(orgId, orgUser): Observable<IOrgUser> {
    return this.httpClient
      .delete<IOrgUser>(`${environment.accountServiceEndpoint}/orgs/${orgId}/invitations/${orgUser.orgUserID}`)
      .pipe(catchError(this.errorHandlingService.handleHttpError));
  }

  updateUserWork(userId: string, work: any): Observable<any> {
    return this.httpClient
      .put(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/users/${userId}/work`, work)
      .pipe(
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  fetchUserPlanSummaries() {
    return this.httpClient
      .get(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/userPlanSummaries`)
      .pipe(
        tap((resp: any) => {
          this.userPlanSummaries.next(resp);
        }),
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  fetchLastAddDoc(): Observable<any> {
    console.log(' 🐷');
    return this.httpClient
      .get(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/lastdocdates`)
      .pipe(
        tap((resp: any) => this.lastDocumented.next(resp)),
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  public fetchCachedLastAddDoc(refresh?: boolean): Observable<any> {
    const lastDocumented = this.lastDocumented.value;
    if (this._pendingLastDocumented$) {
      return this._pendingLastDocumented$;
    } else if (lastDocumented && lastDocumented.length && !refresh) {
      return this.lastDocumented.asObservable();
    }
    const lastDocumented$ = this.fetchLastAddDoc();
    return this._pendingLastDocumented$ = lastDocumented$.pipe(
      share(),
      tap(() => delete this._pendingLastDocumented$),
      catchError((e) => {
        delete this._pendingLastDocumented$;
        return this.errorHandlingService.handleHttpError(e);
      }),
    );
  }


  public fetchCachedUserPlanSummaries(refresh?: boolean): Observable<any> {
    const userPlanSummaries = this.userPlanSummaries.value;
    if (this._pendingUserPlanSummaries$) {
      return this._pendingUserPlanSummaries$;
    } else if (userPlanSummaries && userPlanSummaries.length && !refresh) {
      return this.userPlanSummaries.asObservable();
    }
    const userPlanSummaries$ = this.fetchUserPlanSummaries();
    return this._pendingUserPlanSummaries$ = userPlanSummaries$.pipe(
      share(),
      tap(() => delete this._pendingUserPlanSummaries$),
      catchError((e) => {
        delete this._pendingUserPlanSummaries$;
        return this.errorHandlingService.handleHttpError(e);
      }),
    );
  }


  public generateUserSnapshots(userId: string): Observable<any> {
    return this.httpClient
      .get(environment.accountServiceEndpoint + '/orgs/' + localStorage.getItem('orgID') + '/users/' + userId + '/snapshots/create')
      .pipe(catchError(this.errorHandlingService.handleHttpError));
  }

  public fetchUserSnapshots(userId: string): Observable<any> {
    return this.httpClient
      .get(environment.accountServiceEndpoint + '/orgs/' + localStorage.getItem('orgID') + '/users/' + userId + '/snapshots')
      .pipe(catchError(this.errorHandlingService.handleHttpError));
  }

  // No longer used
  public stopOrganisationUser(userId: string) {
    const orgId = localStorage.getItem('orgID');
    return this.httpClient
      .get(environment.accountServiceEndpoint + '/orgs/' + orgId + '/users/' + userId + '/stop')
      .pipe(
        catchError(this.errorHandlingService.handleHttpError),
      )
      .toPromise();
  }

  // replaces stopOrganisationUser
  public deactivateUser(userId: string) {
    const orgId = localStorage.getItem('orgID');
    return this.httpClient
      .get(environment.accountServiceEndpoint + '/orgs/' + orgId + '/orgUsers/' + userId + '/deactivate')
      .pipe(
        catchError(this.errorHandlingService.handleHttpError),
      )
      .toPromise();
  }
  public removeStaffFromOrg(orgId: string, userId: string) {
    return this.httpClient
      .delete(environment.accountServiceEndpoint + '/orgs/' + orgId + '/users/' + userId)
      .pipe(
        catchError(this.errorHandlingService.handleHttpError),
      )
      .toPromise();
  }

  getPDFFromHtml(htmlObject) {
    return this.httpClient.post(environment.htmpToPdfAPIGateway, htmlObject).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  // only used for Juniper at the moment
  syncOktaUser() {
    return this.httpClient.get(environment.accountServiceEndpoint + `/orgs/${localStorage.getItem('orgID')}/syncOktaUsers`).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  // used in remove staff/revoke staff
  removeOrgUserInCachedOrganisationUsers(orgUserId: string, email?: string): void {
    // console.log(this.usersEmailDict);
    const usersCopy: any = {};
    if (this.classifiedOrgUsers.value) {
      for (const type of Object.keys(this.classifiedOrgUsers.value)) {
        usersCopy[type] = this.classifiedOrgUsers.value[type].filter(user => user.orgUserID !== orgUserId);
      }
      // Set up users email dict for duplicate email lookup;
      if (email) {
        this.usersEmailDict.delete(email.toLocaleLowerCase());
      }
      this.deletedUser = orgUserId;
      // update search bar's recent views
      let recentlyViewedItems = JSON.parse(localStorage.getItem('recentlyViewedItems')) || [];
      recentlyViewedItems = recentlyViewedItems.filter(item => item.orgUserID !== orgUserId);
      localStorage.setItem('recentlyViewedItems', JSON.stringify(recentlyViewedItems));

      this.classifiedOrgUsers.next(usersCopy);
    }
  }

  /**
  * @param orgUser
  * This method will mutably extend active job role information to the orgUser;
  * This is not a good pattern but activeJobRoleTakes property has spread across the whole website
  * So, I have decided to move the extension into the very beginning of the api calls;
  *
  * In the future, you do not need to call getCurrentJobRoleTakens in the group service anymore.
  * */
  setActiveJobRoleTakens(orgUser: IOrgUser) {
    const activeJobRoleTakens = [];
    if (orgUser.jobRoleTakens && Array.isArray(orgUser.jobRoleTakens)) {
      orgUser.jobRoleTakens.forEach((taken => {
        if (taken && !!taken.jobRoleModel && isBetweenDate(new Date().getTime(), new Date(taken.startDateUTC).getTime(), new Date(taken.endDateUTC).getTime())) {
          activeJobRoleTakens.push(taken);
        }
      }));
    }
    orgUser['activeJobRoleTakens'] = activeJobRoleTakens;
    orgUser['jobRoleType'] = getColJobRoleType(pluck(activeJobRoleTakens, 'jobRoleModel'))
  }

  setFutureJobRoleTakens(orgUser: IOrgUser) {
    const futureJobRoleTakens = [];
    if (orgUser.jobRoleTakens && Array.isArray(orgUser.jobRoleTakens)) {
      orgUser.jobRoleTakens.forEach((taken => {
        if (taken && !!taken.jobRoleModel && dayjs(taken.startDateUTC).isAfter(dayjs())) {
          futureJobRoleTakens.push(taken);
        }
      }));
    }
    orgUser['futureJobRoleTakens'] = futureJobRoleTakens;
  }

  // if need all users in report api
  getAllUserAndStaffID(): Observable<{filteredUsers: string[], filteredStaffIDs: string[]}> {
    return this.fetchCachedOrgUsers(localStorage.getItem('orgID'))
      .pipe(switchMap(() => {
        const allManagedUsers = Object.values(this.managedOrgUserDictionaryByOrgUserID)
        return of({
          filteredUsers: pluck(allManagedUsers, 'userID').filter(id => id),
          filteredStaffIDs: pluck(allManagedUsers, 'staffID').filter(id => id),
        })
      }))
  }

  getFullNameGlobally(id: {orgUserID: string; userID: string}) {
    let raw;
    if (id.orgUserID) {
      raw = this.allRawOrgUsers.find(u => u.orgUserID === id.orgUserID);
    }
    if (id.userID && !raw) {
      raw = this.allOrgUserDictionaryByUserID[id.userID]
    }
    if (raw) {
      return this._spreadOrgUserBasedOnInvitationAndDetails(raw).fullName;
    }
    return null;
  } 

  get permissionType() {
    if (this.managedTeamModels) {
      return this.isManager ? 'Organisation Manager'
        : !this.managedTeams?.length ? 'Learner'
          : this.managedTeamModels.every(t => LOCATION_TYPE_DISPLAY[t.teamType]) ? 'Location Manager'
            : 'Team Manager';
    }

  }
}

export interface IOrgUserDetail {
  teamModels?: ITeam[];
  managedTeamModels?: ITeam[];
  managedAllTeams?: string[];
  jobRoleTakens?: IJobRoleTaken[];
}

export interface IOrgUser {
  orgUserID?: string;
  userID?: string;
  orgID?: string;
  isManager?: boolean;
  staffID?: string;
  internalID?: string;
  work?: any;
  teams?: string[]; // not include facilities
  managedTeams?: string[];
  activeJobRoleTakens?: IJobRoleTaken[];
  futureJobRoleTakens?: IJobRoleTaken[]; // for IRTLMS only
  jobRoleTakens?: IJobRoleTaken[];
  status?: USER_STATUS; // Pending, Invited, Active, Rejected, In-active, Applied, Refused
  startDate?: string;
  endDate?: string;
  orgEmail?: string;
  orgMobile?: string;
  userDetail?: any;
  email?: string;
  invitation?: IOrgInvitation;
  application?: IOrgApplication;
  fullName?: string;
  workStartDate?: string;
  profilePicURL?: string;
  orgUserDetail?: IOrgUserDetail;
  lastReminded?: string;
  profile?: OrgUserProfile;
  facilities?: string[];
  allTeams?: string[]; // teams + facilities
  facilityModels?: ITeam[];
  allTeamModels?: ITeam[];
  periods?: IOrgUserPeriod[];
  managedHierarchy?: string;
  hierarchyTeamModel?: ITeam;
  allManagedTeamModels?: ITeam[];
  firstName?: string;
  lastName?: string;
  username?: string;
  [key: string]: any;
}

export type IBackendUserStatus = 'Pending' | 'Invited' | 'Active' | 'Rejected' | 'In-active' | 'Applied' | 'Refused';

export interface OrgUserProfile {
  firstName?: string;
  lastName?: string;
  preferredName?: string;
  dateOfBirth?: string;
  gender?: string; // Male, Female, Non-binary, Prefer not to say
  position?: string;
  workType?: string;
  department?: string;
  startDate?: string;
  endDate?: string;
  adminNote?: string;
  internalID?: string;
}
/* any as a placeholder for the field. If you are working on the attribute, please contact Evan and complete the interface. */
export interface IRawBackendOrgUser {
  orgUserID: string;
  userID?: string;
  orgID: string;
  isManager: boolean;
  staffID?: string;
  userDetail?: any;
  work?: IWork;
  teams?: string[];
  managedTeams?: string[];
  jobRoleTakens?: IJobRoleTaken[];
  status: IBackendUserStatus;
  startDate?: string;
  endDate?: string;
  orgEmail?: string;
  orgMobile?: string;
  invitation?: IOrgInvitation;
  application?: IOrgApplication;
  orgUserDetail?: IOrgUserDetail;
  profile?: OrgUserProfile;
  periods?: IOrgUserPeriod[];
}

export interface IJobRoleTakenForm {
  jobRoleTakenID?: string;
  jobRole: string;
  startDate?: string;
  endDate?: string;
}

export interface IBundleTakenForm {
  bundleTakenID?: string;
  bundle: string;
  startDate?: string;
  endDate?: string;
}

export interface IOrgUserPeriod {
  addedDatetime: string;
  addedBy?: string;
  firstInvitedDate?: string;
  firstInvitedBy?: string;
  invitedDatetimes?: string[];
  revokedDate?: string;
  revokedDatetime?: string;
  revokedBy?: string;
  activatedDate?: string;
  activatedDatetime?: string;
  deactivatedDate?: string;
  deactivatedDatetime?: string;
  deactivatedBy?: string;
  isFreeUsage?: boolean;
}

export interface IEditOrgUserForm {
  userID?: string;
  isManager?: boolean;
  staffID?: string;
  teams?: string[];
  managedTeams?: string[];
  jobRoleTakens?: IJobRoleTakenForm[];
  bundleTakens?: IBundleTakenForm[];
  orgEmail?: string;
  orgMobile?: string;
  profile?: OrgUserProfile;
}

export interface IEditOrgUserFormBulk extends IEditOrgUserForm {
  orgUserID?: string;
}


export interface IClassifiedOrgUsers {
  applied: IOrgUser[];
  invited: IOrgUser[];
  // oldInvited: IOrgUser[];
  pending: IOrgUser[];
  // oldPending: IOrgUser[];
  active: IOrgUser[];
  rejected: IOrgUser[];
  // oldRejected: IOrgUser[];
  inActive: IOrgUser[];
  // oldInactive: IOrgUser[];
  refused: IOrgUser[];
  valid: IOrgUser[];
  hasUserIDs: IOrgUser[];
  all: IOrgUser[];
}

export interface IOrgInvitation {
  firstName: string;
  lastName: string;
  position?: string;
  workType?: string;
  department?: string;
  startDate?: string;
  sentDate?: string;
}

interface IOrgApplication {
  orgEmail?: string;
  teams?: string[];
  jobRoles?: string[];
  staffID?: string;
  position?: string;
  workType?: string;
  department?: string;
  startDate?: string;
}

export interface IWork {
  workID: string;
  owner: string;
  workplace: string;
  position?: string;
  workType?: string;
  department?: string;
  startDate?: string;
  endDate?: string;
  reviewDate?: string;
}

export interface IOrgInvitationForm {
  isManager?: boolean;
  staffID?: string;
  teams?: string[];
  jobRoleTakens?: IJobRoleTaken[];
  managedTeams?: string[];
  orgEmail: string;
  invitation: IOrgInvitation;
  orgMobile?: string;
  profile?: OrgUserProfile;
  bundleTakens?: IBundleTakenForm[];
}

export enum USER_STATUS {
  active = 'Active',
  pending = 'Pending',
  invited = 'Invited',
  inactive = 'In-active',
  rejected = 'Rejected',
}

export enum USER_PERMISSION_TYPE {
  organisationManager = 'Organisation Manager',
  teamManager = 'Team Manager',
  learner = 'Learner'
}


