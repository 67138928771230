<div>
    <div class="modal-header p-5">
        <!-- For complex headings use headingSlot -->
        <ng-container *ngTemplateOutlet="headingSlot"></ng-container>
        <!-- For single line headings, pass in the heading prop -->
        <div class="flex-shrink-1" *ngIf="heading">
            <div class="d-flex align-items-center">
                <app-back-button class="me-2" *ngIf="backButton" (backClick)="backClick.emit()"></app-back-button>
                <ui-streamline-icon *ngIf="icon" class="me-2" [slIcon]="{name: 'interface-alert-warning-circle',
                    theme: 'micro-bold', color: 'fill-' + icon, svgStyle: {'padding-bottom': '2px'}}"></ui-streamline-icon>
                <h3 class="text-neutral-900" *ngIf="heading" [innerHtml]="heading"></h3>
            </div>
            <p class="f-small text-neutral-700">{{description}}</p>
        </div>
        <button *ngIf="!hideCloseButton" (click)="closeModal()" type="button" class="btn btn-icon btn-square flex-shrink-0 align-self-start" data-dismiss="modal" aria-label="Close" tabindex="-1" [disabled]="disableCloseButton">
            <ui-streamline-icon [slIcon]="{name: 'interface-delete-1', theme: 'micro-bold', size: 'xs'}"></ui-streamline-icon>
        </button>
    </div>

    <div *ngIf="loading" class="text-center"><ui-spinner></ui-spinner></div>
    <ng-container *ngIf="!loading">
        <div class="{{scrollable ? 'border-top border-bottom' : 'modal-body'}}" [ngClass]="{'scroll-area': maxHeight}" [style.min-height]="bodyHeight">
            <ng-container [ngTemplateOutlet]="contentSlot"></ng-container>
        </div>
    
        <div class="modal-footer" *ngIf="leftSlot || rightSlot || msgSlot">
            <!-- Slot for LHS destroy button -->
            <div class="d-flex justify-content-between">
                <div>
                    <ng-container *ngTemplateOutlet="leftSlot"></ng-container>
                </div>
    
                <!-- Slot for RHS CTA's -->
                <div class="d-flex children-gap-2">
                    <ng-container *ngTemplateOutlet="rightSlot; context : {content:  content}"></ng-container>
                </div>
            </div>
    
            <!-- Slot for success/error messages -->
            <div class="text-end mt-2">
                <ng-container *ngTemplateOutlet="msgSlot"></ng-container>
            </div>
        </div>
    </ng-container>
    
</div>
