<div>

  <!-- <app-shortcut-button-container *ngIf="withSaveFilter" class="me-2"></app-shortcut-button-container> -->
  
  <button class="btn  btn-outline-light" (click)="onClick()">
    <div class="fw-semibold d-flex f-caption">
      <ui-streamline-icon [slIcon]="{name: 'interface-download-button-2', theme: 'micro-bold', size: 'sm'}"
      class="me-1 m-transformY-1"></ui-streamline-icon>
      {{text}}
    </div>
  </button>
  
</div>
