import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, combineLatest, of, throwError } from 'rxjs';
import { catchError, delay, map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ErrorHandlingService } from './error-handling.service';
import { ILearningGoal } from './goal.service';
import { ITableUser, SmartTableColumnsService } from './smart-table-columns.service';
import { DataCacher } from '../shared/utils/class/DataCacher';
import { BroadcastService } from './broadcast.service';
import { IOrgUser, UserService } from './user.service';
import { clone, Dictionary, indexBy, isNumber } from 'underscore';
import { HierarchyService } from '../pages/admin/hierarchy/hierarchy.service';
import { getTaskStatusDisplay } from './service-utils/performance-review-service-utils';
import { TableColumnValuePrepareAndSortUtilsService } from './table-column-value-prepare-and-sort-utils.service';
import { IPerformanceReviewQuestionType } from '../performance-review-builder-tool/performance-review-builder-tool.service';
import { ISingleSelectorOptions } from '../form-builder-tool/fb-side-panel/fb-section-editor/fb-question-card/custom-single-selector/custom-single-selector.component';
import { AnalyticsPrService } from '../pages/performance-review/analytics-pr.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TaskRemindModalComponent } from '../pages/performance-review/reviews/progress/task-activity-tables/task-table/task-remind-modal/task-remind-modal.component';

export interface IPerformanceReviewTaskTemplate extends IPerformanceReviewTaskTemplateForm {
    templateID: string;
    currentVersion?: IPerformanceReviewTaskTemplateVersion;
    pastVersions?: IPerformanceReviewTaskTemplateVersion[];
    orgID?: string,
    createDate: string, //"2024-07-29T23:17:02",
    createdBy: string,
    updateDate: string, //"2024-07-29T23:17:02",
    updatedBy: string,
}

export interface IPerformanceReviewTaskTemplateForm {
    title: string,
    taskType: TASK_TEMPLATE_TYPE,
    completionType: 'Manager' | 'Staff' | 'Both', // 'Both' is de-scoped in current version
    code?: string,
    description?: string,
    status: TASK_TEMPLATE_STATUS
}

export interface IPerformanceReviewTaskTemplateWithVersions {
    template: IPerformanceReviewTaskTemplate,
    currentVersion?: IPerformanceReviewTaskTemplateVersion;
    pastVersions?: IPerformanceReviewTaskTemplateVersion[];
}

export interface IPerformanceReviewTaskTemplateVersion {
    version: number;
    createDate: string;
    createBy: string;
    taskObjects?: IPerformanceReviewTaskObject[];
}

export interface IPerformanceReviewTaskObject {
    objectID: string;
    fn_objectType: TASK_TEMPLATE_TYPE;
    name?: string;
    order?: number;
    references?: string[];
    linkedResource?: string;
    questions?: IPerformanceReviewTaskQuestion[];
}

export type QuestionType = 'single-answer' | 'short-answer' | 'private-reflection' | 'multi-answers' | 'dropdown' | 'number' | 'ratings' | 'date-picker';

export interface IPerformanceReviewTaskQuestion {
    questionID: string; // generate on the frontend
    objectID?: string;
    qType?: QuestionType;
    order?: number;
    text?: string;
    rationale?: string;
    options?: string[];
    answer?: number;
    multipleAnswers?: number[];
    isSkippable?: boolean; // use for mandatory check, default value is false => questions is mandatory
}


export interface IPerformanceReviewAssign {
    taskID: string;
    // taskType: string;
    cycleID: string;
    // title: string;
    // assignee: string;
    assigneeOrgUserID: string;
    assigneeUserID?: string;
    managerUserID?: string;
    status: TASK_ASSIGN_STATUS_ENUM;
    openDate?: string;
    dueDate?: string;
    completeDate?: string;
    templateID: string;
    taskTemplate?: IPerformanceReviewTaskTemplate;
    taskConfigID?: string;
    cycle?: IPerformanceReviewCycleConfig;
}

export interface IPerformanceReviewAssignFull extends IPerformanceReviewAssign {
    response?: IPerformanceReviewTaskResponse;
    managerComment?: IPerformanceReviewManagerComment;
}

export interface IPerformanceReviewAssignFullBackend {
    task: IPerformanceReviewAssign;
    staffResponse?: IPerformanceReviewTaskResponse;
    managerResponse?: IPerformanceReviewManagerComment
}

export interface IPerformanceReviewTaskConfig {
    taskConfigID: string;
    cycleConfigID: string;
    taskTemplateID: string;
    order?: number;
    dueDuration?: string;
    assignDuration?: string;
    openDay?: number;
    openMonth?: number;
    dueDay?: number;
    dueMonth?: number;
    startDate?: string;
    endDate?: string;
    taskTemplate?: IPerformanceReviewTaskTemplate;
    progress?: number; // not in the API
}

export interface IPerformanceReviewTaskConfigForm {
    taskConfigID?: string;
    templateID: string;
    order?: number;
    dueDuration?: string;
    assignDuration?: string;
    openDay?: number;
    openMonth?: number;
    dueDay?: number;
    dueMonth?: number;
    startDate?: string;
    endDate?: string;
}

export interface IPerformanceReviewCycleConfig {
    cycleConfigID: string;
    orgID: string;
    title: string;
    description?: string;
    status: string; // Draft, Active, Archived
    cycleType: string;
    openDay?: number;
    openMonth?: number;
    duration?: string;
    startDate?: string;
    endDate?: string;
    activityNum?: number;
    enrolledUsers?: number;
    completionRate?: number; // not in API
    createDate?: string;
}

export interface IPerformanceReviewTaskConfigWithTemplates {
    taskConfig: IPerformanceReviewTaskConfig;
    taskTemplate?: IPerformanceReviewTaskTemplate;
}

export interface IPerformanceReviewCycleConfigWithExtras {
    config: IPerformanceReviewCycleConfig;
    taskConfigWithTemplates?: IPerformanceReviewTaskConfigWithTemplates[];
    enrolments?: IPerformanceReviewEnrolment[];
}

export interface IPerformanceReviewEnrolment {
    cycleEnrolmentID: string;
    cycleConfigID: string;
    orgUserID: string;
    enrolmentDate: string;
    unenrolmentDate?: string;
    orgUser?: IOrgUser; // not in the API
}

export interface IPerformanceReviewEnrolmentForm {
    cycleEnrolmentID?: string;
    orgUserID: string;
    enrolmentDate: string;
    unenrolmentDate?: string;
}

export enum CYCLE_TYPE_ENUM {
    once = 'Once-Off',
    annualEnrolment = 'Annual-Enrolment',
    annualDates = 'Annual-Dates',
    custom = 'Custom'
}

export const CYCLE_TYPE_Dict = {
    [CYCLE_TYPE_ENUM.once]: 'Once Only Cycle',
    [CYCLE_TYPE_ENUM.annualEnrolment]: 'Annual Cycle on Enrolment Date',
    [CYCLE_TYPE_ENUM.annualDates]: 'Annual Cycle on Selected Date',
    [CYCLE_TYPE_ENUM.custom]: 'Cycle on Custom Date',
};

export enum TASK_ASSIGN_STATUS_ENUM {
    open = 'Assigned',
    completed = 'Completed',
    overdue = 'Overdue',
    scheduled = 'Scheduled'
}

export type CYCLE_TYPE = CYCLE_TYPE_ENUM.once | CYCLE_TYPE_ENUM.annualEnrolment | CYCLE_TYPE_ENUM.annualDates | CYCLE_TYPE_ENUM.custom;


export interface IPerformanceReviewTaskObject {
    objectID: string;
    objectType: TASK_TEMPLATE_TYPE;
    name?: string;
    order?: number;
    references?: string[];
    linkedResource?: string;
    questions?: IPerformanceReviewTaskQuestion[];
}



export enum TASK_TEMPLATE_ENUM {
    assessment = 'Assessment',
    meeting = 'Meeting',
}

export enum TASK_TEMPLATE_STATUS {
    publish = 'Published',
    draft = 'Draft',
}
export type TASK_TEMPLATE_TYPE = TASK_TEMPLATE_ENUM.meeting | TASK_TEMPLATE_ENUM.assessment;
export const TEMPLATE_TYPE_DISPLAY: Map<TASK_TEMPLATE_TYPE, string> = new Map([[TASK_TEMPLATE_ENUM.meeting, 'Meeting'], [TASK_TEMPLATE_ENUM.assessment, 'Self Assessment']])

export interface IPerformanceReviewTaskQuestion {
    questionID: string;
    qType?: TaskQuestionType;
    order?: number;
    text?: string;
    rationale?: string;
    options?: string[];
    answer?: number;
    multipleAnswers?: number[];
    isSkippable?: boolean; // use for mandatory check, default value is false => questions is mandatory
}

export type TaskQuestionType = 'single-answer' | 'short-answer' | 'private-reflection' | 'multi-answers' | 'dropdown' | 'number' | 'ratings' | 'date-picker';

export interface IPerformanceReviewTaskResponse {
    version: number;
    responses: IPerformanceReviewTaskQuestionResponse[];
    responseDate?: string;
}

export interface IPerformanceReviewTaskQuestionResponse {
    questionID: string;
    answerOption?: number;
    // @ak check w b2c
    multipleAnswers?: number[];
    answerText?: string;
}

export interface IPerformanceReviewManagerComment {
    version: number;
    responses: IPerformanceReviewManagerResponseComment[];
    managerNote: string;
    responseDate?: string;
}

export interface IPerformanceReviewManagerResponseComment {
    questionID: string;
    responseText?: string;
}


export interface ITablePerformanceReviewAssign extends IPerformanceReviewAssign, ITableUser {
    taskManager?: IOrgUser;
    taskTypeDisplay?: string;
}
export interface ITablePerformanceReviewAssignFull extends IPerformanceReviewAssignFull, ITableUser {
    taskManager?: IOrgUser;
    taskTypeDisplay?: string;
}

export interface ITaskQueryForm {
    filteredOrgUsers?: string[];
    filteredUsers?: string[];
    filteredCycles?: string[];
    filteredStatuses?: string[];
    filteredManagers?: string[];
}

export interface IPerformanceReviewCycle {
    cycleID: string;
    cycleConfigID: string;
}

export const TEMPLATE_QUESTION_TYPE_OPTIONS: ISingleSelectorOptions<IPerformanceReviewQuestionType>[] = [{
    value: 'short-answer', display: 'Short Answer', slIcon: { name: 'shortAnswerIcon' }
  },
  { value: 'private-reflection', display: 'Long Answer', slIcon: { name: 'paragraph-justified-align' } },
  { value: 'single-answer', display: 'Multiple choice', hasBottomBorder: true, slIcon: { name: 'button-record-alternate' } },
  { value: 'multi-answers', display: 'Checkbox', slIcon: { name: 'check-2-alternate' } },
  { value: 'dropdown', display: 'Dropdown', slIcon: { name: 'arrow-button-down-2' } },
  { value: 'number', hasBottomBorder: true, display: 'Number Field', slIcon: { name: 'type-cursor-1' } },
  { value: 'ratings', display: 'Rating (1 to 10)', slIcon: { name: 'rating-star-alternate' } },
  { value: 'date-picker', display: 'Date Picker', slIcon: { name: 'calendar' } },
    // {value: 'upload', display: 'File Upload', slIcon: {name: 'cloud-upload'}},
  ];


export const CYCLES_DES = [
    {
        cycleType: CYCLE_TYPE_ENUM.once,
        title: 'Once Only Cycle',
        description: 'Add a review cycle that users must complete once - such as a probation review period for staff who have recently joined your organisation',
        image: 'assets/images/performance-review/cycle-once-off.svg',
        icon: 'task-list-checklist',
        bgColor: 'bg-green-100',
        color: 'text-green-800',
        available: true
    },
    {
        cycleType: CYCLE_TYPE_ENUM.annualEnrolment,
        title: `Annual Cycle on Enrolment Date`,
        description: 'Add a review cycle that reoccurs every year based on the users enrolment date - such as an annual performance review for front line staff on their work start date',
        image: 'assets/images/performance-review/cycle-annual-fixed.svg',
        icon: 'human-resource-employee',
        bgColor: 'bg-yellow-100',
        color: 'text-yellow-800',
        available: true
    },
    {
        cycleType: CYCLE_TYPE_ENUM.annualDates,
        title: 'Annual Cycle on Selected Date',
        description: `Add a review cycle that reoccurs every year based on fixed start and end dates - such as an annual performance review for all head office staff in a given month`,
        image: 'assets/images/performance-review/cycle-annual-custom.svg',
        icon: 'plane-trip-round',
        bgColor: 'bg-blue-100',
        color: 'text-blue-800',
        available: true
    }
    // {
    //     cycleType: CYCLE_TYPE_ENUM.custom,
    //     title: 'Custom',
    //     description: `Create your own custom cycle with our intuitive scheduling options`,
    //     shortDesc: 'Custom cycle',
    //     image: 'assets/images/performance-review/cycle-custom.svg',
    //     icon: 'cog-double',
    //     bgColor: 'bg-neutral-100',
    //     color: 'text-neutral-800',
    //     available: false
    // }
];

@Injectable({
    providedIn: 'root'
})
export class PerformanceReviewService {
    private _orgID = localStorage.getItem('orgID');
    http = inject(HttpClient);
    colService = inject(SmartTableColumnsService);
    errorHandlingService = inject(ErrorHandlingService);
    broadcastService = inject(BroadcastService);
    userService = inject(UserService);
    hierarchyService = inject(HierarchyService);
    tableUtil = inject(TableColumnValuePrepareAndSortUtilsService);
    private analyticsPrService = inject(AnalyticsPrService);
    modalService = inject(NgbModal);

    orgActivities: ILearningGoal[];
    cycleConfigs: IPerformanceReviewCycleConfig[];
    private _cycleConfigsCacher: DataCacher<IPerformanceReviewCycleConfig[]> = new DataCacher<IPerformanceReviewCycleConfig[]>(this.fetchCycleConfigs(this._orgID), this.broadcastService);
    private _cycleConfigWithExtrasCacher: DataCacher<IPerformanceReviewCycleConfigWithExtras[]> = new DataCacher<IPerformanceReviewCycleConfigWithExtras[]>(this.fetchCycleConfigsWithExtras(this._orgID), this.broadcastService);

    _myTasksCacher = new DataCacher<ITablePerformanceReviewAssign[]>(this.fetchMyTasks(), inject(BroadcastService));
    // _myTasksCacher = new DataCacher<ITablePerformanceReviewAssign[]>(this.fetchMyTasks(), inject(BroadcastService));
    private _allTemplatesCacher = new DataCacher<IPerformanceReviewTaskTemplate[]>(this.fetchTaskTemplates(), inject(BroadcastService));

    private _cycleCacher = new DataCacher<IPerformanceReviewCycle[]>(this.fetchCycles(), this.broadcastService)

    taskTemplateDictByTaskID: Dictionary<IPerformanceReviewTaskTemplate>;
    cycleDic: Dictionary<IPerformanceReviewCycle>;
    cycleConfigDic: Dictionary<IPerformanceReviewCycleConfig>;

    fetchTaskTemplates(): Observable<IPerformanceReviewTaskTemplate[]> {
        const url = `${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/perfReviewTaskTemplates`;
        return this.http.get<IPerformanceReviewTaskTemplateWithVersions[]>(url).pipe(
            catchError(this.errorHandlingService.handleHttpError),
            map(templates => {
                return templates.map(t => ({ ...t.template, currentVersion: t.currentVersion, pastVersions: t.pastVersions }))
            }),
            tap(templates => this.taskTemplateDictByTaskID = indexBy(templates, 'templateID') as any),
        );
    }

    fetchCacheTaskTemplates(refresh = false): Observable<IPerformanceReviewTaskTemplate[]> {
        return this._allTemplatesCacher.fetchCachedData(refresh);
    }

    getTemplateByID(id): Observable<IPerformanceReviewTaskTemplate> {
        const url = `${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/perfReviewTaskTemplates/${id}`;
        return this.http.get<IPerformanceReviewTaskTemplateWithVersions>(url).pipe(
            catchError(this.errorHandlingService.handleHttpError),
            tap(t => console.log(t)),
            map(t => ({ ...t.template, currentVersion: t.currentVersion, pastVersions: t.pastVersions }))
        )
    }

    deleteTaskTemplate(templateID: string) {
        const url = `${environment.accountServiceEndpoint}/orgs/${this._orgID}/perfReviewTaskTemplates/${templateID}`;
        return this.http.delete<IPerformanceReviewTaskTemplateWithVersions[]>(url).pipe(
            catchError(this.errorHandlingService.handleHttpError),
        );
    }

    createTaskTemplate(payload: IPerformanceReviewTaskTemplateForm) {
        const url = `${environment.accountServiceEndpoint}/orgs/${this._orgID}/perfReviewTaskTemplates`;
        return this.http.post<IPerformanceReviewTaskTemplateWithVersions>(url, payload).pipe(
            catchError(this.errorHandlingService.handleHttpError),
            tap(template => this.analyticsPrService.sendTemplateTrack('PR-activity-added', template.template))
        );
    }

    updateTaskTemplate(templateID: string, payload: IPerformanceReviewTaskTemplateForm) {
        const url = `${environment.accountServiceEndpoint}/orgs/${this._orgID}/perfReviewTaskTemplates/${templateID}`;
        return this.http.put<IPerformanceReviewTaskTemplateWithVersions>(url, payload).pipe(
            catchError(this.errorHandlingService.handleHttpError),
        );
    }

    // just for draft status
    updateCurrentVersion(templateID: string, taskObject: IPerformanceReviewTaskObject) {
        const url = `${environment.accountServiceEndpoint}/orgs/${this._orgID}/perfReviewTaskTemplates/${templateID}/versions/current`;
        const payload = { taskObjects: [taskObject] };
        return this.http.put<IPerformanceReviewTaskTemplateWithVersions>(url, payload).pipe(
            catchError(this.errorHandlingService.handleHttpError),
        );
    }

    // just for publish status
    addNewTemplateVersion(templateID: string, taskObject: IPerformanceReviewTaskObject) {
        const url = `${environment.accountServiceEndpoint}/orgs/${this._orgID}/perfReviewTaskTemplates/${templateID}/versions`;
        const payload = { taskObjects: [taskObject] };
        return this.http.post<any>(url, payload).pipe(
            catchError(this.errorHandlingService.handleHttpError),
        );
    }

    publishDraftTemplate(templateID: string) {
        const url = `${environment.accountServiceEndpoint}/orgs/${this._orgID}/perfReviewTaskTemplates/${templateID}/publish`;
        return this.http.get<IPerformanceReviewTaskTemplateWithVersions>(url).pipe(
            catchError(this.errorHandlingService.handleHttpError),
            tap(template => this.analyticsPrService.sendTemplateTrack('PR-activity-published', template.template))
        );
    }

    fetchCycleConfigs(orgID: string): Observable<IPerformanceReviewCycleConfig[]> {
        return this.http
            .get<IPerformanceReviewCycleConfig[]>(`${environment.accountServiceEndpoint}/orgs/${orgID}/perfReviewCycleConfigs`)
            .pipe(
                catchError(this.errorHandlingService.handleHttpError),
                tap(cycleConfigs => this.cycleConfigDic = indexBy(cycleConfigs, 'cycleConfigID')),
                tap(() => this.refreshTasks().subscribe())
            );
    }

    fetchCacheCycleConfigs(refresh = false): Observable<IPerformanceReviewCycleConfig[]> {
        return this._cycleConfigsCacher.fetchCachedData(refresh);
    }

    fetchCycleConfigsWithExtras(orgID: string): Observable<IPerformanceReviewCycleConfigWithExtras[]> {
        return this.http
            .get<IPerformanceReviewCycleConfigWithExtras[]>(`${environment.accountServiceEndpoint}/orgs/${orgID}/perfReviewCycleConfigWithExtras`)
            .pipe(
                catchError(this.errorHandlingService.handleHttpError),
                map(configs => {
                    return configs.map(c => {
                        return {
                            ...c,
                            enrolments: c.enrolments?.filter(e => this.userService.isManager ? true
                                : this.hierarchyService.getMyDirectReports()?.find(u => u.orgUserID === e.orgUserID))
                                || []
                        }
                    })
                })
            );
    }

    fetchCacheCycleConfigsWithExtras(refresh = false): Observable<IPerformanceReviewCycleConfigWithExtras[]> {
        return this._cycleConfigWithExtrasCacher.fetchCachedData(refresh);
    }

    addCycleConfigs(orgID: string, cycleConfig): Observable<IPerformanceReviewCycleConfig> {
        return this.http
            .post<IPerformanceReviewCycleConfig>(`${environment.accountServiceEndpoint}/orgs/${orgID}/perfReviewCycleConfigs`, cycleConfig)
            .pipe(
                tap((cycleConfig) => {
                    this.fetchCacheCycleConfigs(true).subscribe();
                    this.fetchCacheCycleConfigsWithExtras(true).subscribe();
                    this.analyticsPrService.sendTrack('PR-cycle-added', this.analyticsPrService.getCycleInfo(cycleConfig))
                }),
                catchError(this.errorHandlingService.handleHttpError)
            );
    }

    fetchCycleConfig(orgID: string, configID: string): Observable<IPerformanceReviewCycleConfig> {
        return this.http
            .get<IPerformanceReviewCycleConfig>(`${environment.accountServiceEndpoint}/orgs/${orgID}/perfReviewCycleConfigs/${configID}`)
            .pipe(
                catchError(this.errorHandlingService.handleHttpError)
            );
    }

    fetchCycleConfigWithExtras(orgID: string, configID: string): Observable<IPerformanceReviewCycleConfigWithExtras> {
        return this.http
            .get<IPerformanceReviewCycleConfigWithExtras>(`${environment.accountServiceEndpoint}/orgs/${orgID}/perfReviewCycleConfigWithExtras/${configID}`)
            .pipe(
                catchError(this.errorHandlingService.handleHttpError),
                map(config => {
                    return {
                        ...config,
                        enrolments: config.enrolments?.filter(e => this.userService.isManager ? true
                            : this.hierarchyService.getMyDirectReports()?.find(u => u.orgUserID === e.orgUserID))
                            || []
                    }
                })
            );
    }

    updateCycleConfigs(orgID: string, cycleConfigID: string, newCycleConfig): Observable<IPerformanceReviewCycleConfig> {
        return this.http
            .put<IPerformanceReviewCycleConfig>(`${environment.accountServiceEndpoint}/orgs/${orgID}/perfReviewCycleConfigs/${cycleConfigID}`, newCycleConfig)
            .pipe(
                tap(() => {
                    this.fetchCacheCycleConfigs(true).subscribe();
                    this.fetchCacheCycleConfigsWithExtras(true).subscribe();
                }),
                catchError(this.errorHandlingService.handleHttpError)
            );
    }

    activateCycleConfigs(orgID: string, cycleConfig: IPerformanceReviewCycleConfig): Observable<IPerformanceReviewCycleConfig> {
        return this.http
            .get<IPerformanceReviewCycleConfig>(`${environment.accountServiceEndpoint}/orgs/${orgID}/perfReviewCycleConfigs/${cycleConfig.cycleConfigID}/activate`)
            .pipe(
                tap(() => {
                    this.fetchCacheCycleConfigs(true).subscribe();
                    this.fetchCacheCycleConfigsWithExtras(true).subscribe(configs => {
                        const updated = configs.find(c => c.config?.cycleConfigID === cycleConfig.cycleConfigID)
                        if (updated) {
                            this.analyticsPrService.sendCycleExtrasTrack('PR-cycle-activated', updated);
                        }
                    });
                }),
                catchError(this.errorHandlingService.handleHttpError)
            );
    }

    archiveCycleConfigs(orgID: string, cycleConfig: IPerformanceReviewCycleConfig): Observable<IPerformanceReviewCycleConfig> {
        return this.http
            .get<IPerformanceReviewCycleConfig>(`${environment.accountServiceEndpoint}/orgs/${orgID}/perfReviewCycleConfigs/${cycleConfig.cycleConfigID}/archive`)
            .pipe(
                tap(() => {
                    this.fetchCacheCycleConfigs(true).subscribe();
                    this.fetchCacheCycleConfigsWithExtras(true).subscribe();
                }),
                catchError(this.errorHandlingService.handleHttpError)
            );
    }

    deleteCycleConfigs(orgID: string, cycleConfig: IPerformanceReviewCycleConfig) {
        return this.http
            .delete(`${environment.accountServiceEndpoint}/orgs/${orgID}/perfReviewCycleConfigs/${cycleConfig.cycleConfigID}`)
            .pipe(
                tap(() => {
                    this.fetchCacheCycleConfigs(true).subscribe();
                    this.fetchCacheCycleConfigsWithExtras(true).subscribe();
                }),
                catchError(this.errorHandlingService.handleHttpError)
            );
    }


    bulkAddCycleEnrolments(orgID: string, cycleConfig: IPerformanceReviewCycleConfig, enrolments: IPerformanceReviewEnrolmentForm[]): Observable<IPerformanceReviewEnrolment[]> {
        return this.http
            .post<IPerformanceReviewEnrolment[]>(`${environment.accountServiceEndpoint}/orgs/${orgID}/perfReviewCycleConfigs/${cycleConfig.cycleConfigID}/enrolments/bulk`, enrolments)
            .pipe(
                tap(() => {
                    this.fetchCacheCycleConfigs(true).subscribe();
                    this.fetchCacheCycleConfigsWithExtras(true).subscribe(configs => {
                        const updated = configs.find(c => c.config?.cycleConfigID === cycleConfig.cycleConfigID)
                        if (updated) {
                            this.analyticsPrService.sendCycleExtrasTrack('PR-cycle-users_enrolled', updated);
                        }
                    });
                }),
                catchError(this.errorHandlingService.handleHttpError)
            );
    }

    bulkUpdateCycleEnrolments(orgID: string, cycleConfig: IPerformanceReviewCycleConfig, enrolments: IPerformanceReviewEnrolmentForm[]): Observable<IPerformanceReviewEnrolment[]> {
        return this.http
            .put<IPerformanceReviewEnrolment[]>(`${environment.accountServiceEndpoint}/orgs/${orgID}/perfReviewCycleConfigs/${cycleConfig.cycleConfigID}/enrolments/bulk`, enrolments)
            .pipe(
                tap(() => {
                    this.fetchCacheCycleConfigs(true).subscribe();
                    this.fetchCacheCycleConfigsWithExtras(true).subscribe();
                }),
                catchError(this.errorHandlingService.handleHttpError)
            );
    }

    bulkDeleteCycleEnrolments(orgID: string, cycleConfigID: string, enrolments: IPerformanceReviewEnrolment[]) {
        return this.http
            .delete(`${environment.accountServiceEndpoint}/orgs/${orgID}/perfReviewCycleConfigs/${cycleConfigID}/enrolments/bulk`, { body: enrolments.map(e => e.cycleEnrolmentID) })
            .pipe(
                tap(() => {
                    this.fetchCacheCycleConfigs(true).subscribe();
                    this.fetchCacheCycleConfigsWithExtras(true).subscribe();
                }),
                catchError(this.errorHandlingService.handleHttpError)
            );
    }

    addComment(taskID, payload: { version: number; responses: IPerformanceReviewManagerResponseComment[] }): Observable<IPerformanceReviewAssignFullBackend> {
        return this.http.post<IPerformanceReviewAssignFullBackend>(`${environment.accountServiceEndpoint}/orgs/${this._orgID}/managerPerfReviewTasks/${taskID}/responses`, payload)
            .pipe(
                catchError(this.errorHandlingService.handleHttpError)
            )
    }

    fetchMyTasks() {
        const endPoint = `${environment.accountServiceEndpoint}/orgs/${this._orgID}/sgReports/perfReviewTasks/all`;
        return this.http.post<ITablePerformanceReviewAssign[]>(endPoint, {})
            .pipe(
                map(data => data || []),
                catchError(this.errorHandlingService.handleHttpError),
            )
    }

    fetchMyTaskFull(): Observable<ITablePerformanceReviewAssignFull[]> {
        const endPoint = `${environment.accountServiceEndpoint}/orgs/${this._orgID}/sgReports/perfReviewTaskWithResponses/all`;
        const tasks$: Observable<IPerformanceReviewAssignFull[]> = this.http.post<IPerformanceReviewAssignFullBackend[]>(endPoint, {}).pipe(
            catchError(this.errorHandlingService.handleHttpError),
            map(tasks => tasks.map(t => ({ ...t.task, response: t.staffResponse, managerComment: t.managerResponse }))),
        )
        return this.processTasks(tasks$);
    }

    fetchCachedMyTasks(refresh = false): Observable<ITablePerformanceReviewAssign[]> {
        return this.processTasks(this._myTasksCacher.fetchCachedData(refresh))
    }

    processTasks(tasks$: Observable<IPerformanceReviewAssign[]>) {
        return combineLatest([
            tasks$.pipe(map(tasks => this.filterTasks(tasks))),
            this.fetchCacheCycleConfigs(),
            this.fetchCacheTaskTemplates(),
            this.fetchCachedCycles()
        ]).pipe(map(([tasks, configs, _,]) => {
            return tasks?.map(t => ({
                ...t,
                cycle: this.cycleConfigDic[this.cycleDic[t.cycleID]?.cycleConfigID],
                taskTemplate: this.taskTemplateDictByTaskID[t.templateID]
            })).map((a, i) => ({
                ...a,
                ...this.colService.getUserTableSimple(a.assigneeOrgUserID),
                assignDate: a.openDate,
                status: getTaskStatusDisplay(a),
                taskManager: this.getTaskManager(a),
                taskTypeDisplay: TEMPLATE_TYPE_DISPLAY.get(a.taskTemplate.taskType)
            })) || [] 
        }), map(tasks => this.sortTasks(tasks)));
    }

    filterTasks<T extends IPerformanceReviewAssign>(tasks: T[]) {
        if (this.userService.isManager) {
            return tasks;
        }
        return tasks.filter(t => this.hierarchyService.getMyDirectReports()?.map(u => u.orgUserID)?.includes(t.assigneeOrgUserID))
    }

    sortTasks(tasks: ITablePerformanceReviewAssign[]) {
        const order = new Map<TASK_ASSIGN_STATUS_ENUM, number>([
            [TASK_ASSIGN_STATUS_ENUM.overdue, 0],
            [TASK_ASSIGN_STATUS_ENUM.open, 1], [TASK_ASSIGN_STATUS_ENUM.completed, 2], [TASK_ASSIGN_STATUS_ENUM.scheduled, 3]
        ])
        return tasks.sort((a, b) => this.tableUtil.singleStringCompareFunction(1, a.dueDate, b.dueDate))
            .sort((a, b) => order.get(a.status) - order.get(b.status))
    }

    getTaskManager(task: IPerformanceReviewAssign) {
        if (!task.managerUserID) {
            return null;
        }
        return this.userService.isManager ?
            this.userService.managedOrgUserDictionaryByUserID[task.managerUserID] :
            this.userService.plainOrgUser.userID === task.managerUserID ? this.userService.orgUser.value :
                null;
    }

    updateCachedMyTasks(updatedTask: IPerformanceReviewAssign) {
        return this._myTasksCacher.update((current) => {
            const updatedEl = current.find(el => el.taskID === updatedTask.taskID);
            if (updatedEl) {
                updatedEl.status = updatedTask.status;
                updatedEl.completeDate = updatedTask.completeDate;
                // updatedEl. = updatedTask.managerComment
            }
            return clone(current);
        })
    }

    fetchFullTaskByID(taskID: string): Observable<ITablePerformanceReviewAssignFull> {
        const endPoint = `${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/perfReviewTasks/${taskID}`;
        // return this.getMockAssigns().pipe(map(r => r[2]))
        return this.processTasks(this.http.get<IPerformanceReviewAssignFullBackend>(endPoint).pipe(
            catchError(this.errorHandlingService.handleHttpError),
            map(t => ({ ...t.task, response: t.staffResponse, managerComment: t.managerResponse })),
            map(t => [t]), // convert to array
        )).pipe(map(arr => arr[0])) //
    }

    fetchCycles() {
        const endPoint = `${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/sgReports/perfReviewCycles/all`;
        return this.http.post<IPerformanceReviewCycle[]>(endPoint, {})
            .pipe(
                catchError(this.errorHandlingService.handleHttpError),
                tap(cycles => this.cycleDic = indexBy(cycles, 'cycleID'))
            )
    }

    fetchCachedCycles(refresh = false) {
        return this._cycleCacher.fetchCachedData(refresh);
    }

    refreshTasks() {
        return combineLatest([this.fetchCachedMyTasks(true), this.fetchCachedCycles(true)])
    }

    addCycleTaskConfig(orgID: string, cycleConfigID: string, taskConfig): Observable<IPerformanceReviewTaskConfig> {
        return this.http
            .post<IPerformanceReviewTaskConfig>(`${environment.accountServiceEndpoint}/orgs/${orgID}/perfReviewCycleConfigs/${cycleConfigID}/taskConfigs`, taskConfig)
            .pipe(
                tap(() => {
                    this.fetchCacheCycleConfigs(true).subscribe();
                    this.fetchCacheCycleConfigsWithExtras(true).subscribe(configs => {
                        const updated = configs.find(c => c.config?.cycleConfigID === cycleConfigID)
                        if (updated) {
                            const template = updated.taskConfigWithTemplates.find(withTemplates => withTemplates.taskTemplate?.templateID === taskConfig?.taskTemplateID)?.taskTemplate;
                            if (template) {
                                this.analyticsPrService.sendTrack('PR-cycle-activities_added', {
                                    ...this.analyticsPrService.getCycleInfoWithExtras(updated),
                                    ...this.analyticsPrService.getTemplateInfo(template)
                                })
                                        }
                        }

                    });
                }),
                catchError(this.errorHandlingService.handleHttpError)
            );
    }

    updateCycleTaskConfig(orgID: string, cycleConfigID: string, taskConfigID: string, taskConfig): Observable<IPerformanceReviewTaskConfig> {
        return this.http
            .put<IPerformanceReviewTaskConfig>(`${environment.accountServiceEndpoint}/orgs/${orgID}/perfReviewCycleConfigs/${cycleConfigID}/taskConfigs/${taskConfigID}`, taskConfig)
            .pipe(
                tap(() => {
                    this.fetchCacheCycleConfigs(true).subscribe();
                    this.fetchCacheCycleConfigsWithExtras(true).subscribe();
                }),
                catchError(this.errorHandlingService.handleHttpError)
            );
    }

    removeCycleTaskConfig(orgID: string, cycleConfigID: string, taskConfigID: string) {
        return this.http
            .delete(`${environment.accountServiceEndpoint}/orgs/${orgID}/perfReviewCycleConfigs/${cycleConfigID}/taskConfigs/${taskConfigID}`)
            .pipe(
                tap(() => {
                    this.fetchCacheCycleConfigs(true).subscribe();
                    this.fetchCacheCycleConfigsWithExtras(true).subscribe();
                }),
                catchError(this.errorHandlingService.handleHttpError)
            );
    }

    reorderCycleTaskConfig(orgID: string, cycleConfigID: string, taskConfigOrders): Observable<IPerformanceReviewTaskConfig> {
        return this.http
            .post<IPerformanceReviewTaskConfig>(`${environment.accountServiceEndpoint}/orgs/${orgID}/perfReviewCycleConfigs/${cycleConfigID}/taskConfigs/orders`, taskConfigOrders)
            .pipe(
                tap(() => {
                    this.fetchCacheCycleConfigs(true).subscribe();
                    this.fetchCacheCycleConfigsWithExtras(true).subscribe();
                }),
                catchError(this.errorHandlingService.handleHttpError)
            );
    }

    taskRemind(taskIDs: string[]) {
        return this.http.post<IPerformanceReviewAssign[]>(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/remindTasks`, {taskIDs})
        .pipe(
            catchError(this.errorHandlingService.handleHttpError),
            switchMap(res => res?.length === 0 ? throwError('Task not found') : of(res)),
        );
    }

    remindModal(task: ITablePerformanceReviewAssign, context) {
        const modal = this.modalService.open(TaskRemindModalComponent, { size: 'xl' });
        modal.componentInstance.task.set(task);  
        modal.componentInstance.context = context;
    }

    getMockAssigns(): Observable<ITablePerformanceReviewAssign[]> {
        // return of([])
        return of([
            {
                taskID: '818cf7f7-bd24-48b5-8771-ee68617343ac',
                taskType: TASK_TEMPLATE_ENUM.assessment,
                title: 'Self Reflection 2024',
                assigneeOrgUserID: '02489b70-4e3b-42c2-abe7-4261549f7561',
                status: 'Open',
                dateUpdated: '2024-06-11',
                openDate: '2024-06-10',
                dueDate: '2024-06-17',
                response: {
                    version: 1, responses:
                        [{ questionID: 'questionID-1', responseText: 'response to q1' },
                        { questionID: 'questionID-2', responseOption: 1 }
                        ]
                },
                managerComment: {
                    version: '1', comments: [
                        { questionID: 'questionID-2', commentText: 'comment to q2' },
                        { questionID: 'questionID-1', commentText: 'comment to q1' },
                    ], managerNote: ''
                },
                taskTemplate: {
                    taskType: TASK_TEMPLATE_ENUM.assessment,
                    templateID: '07915789-a8f2-4130-bd8e-494c00b4c0c5',
                    title: 'Development Meeting',
                    status: 'Published',
                    completionType: 'Both'

                },
            },
            {
                taskID: '818cf7f7-bd24-48b5-8771-ee68617343ad',
                taskType: TASK_TEMPLATE_ENUM.assessment,
                title: 'Self Reflection 2024',
                assigneeOrgUserID: '02489b70-4e3b-42c2-abe7-4261549f7561',
                status: 'Open',
                dateUpdated: '2024-06-11',
                openDate: '2024-06-10',
                dueDate: '2024-06-17',
                // response: {version: 1, responses: []},
                taskTemplate: {
                    taskType: TASK_TEMPLATE_ENUM.assessment,
                    templateID: '07915789-a8f2-4130-bd8e-494c00b4c0c5',
                    title: 'Development Meeting',
                    status: 'Published',
                    completionType: 'Staff'
                },
            },
            {
                taskID: '818cf7f7-bd24-48b5-8771-ee68617343ab',
                taskType: TASK_TEMPLATE_ENUM.assessment,
                title: 'Self Reflection 2024',
                assigneeOrgUserID: '02489b70-4e3b-42c2-abe7-4261549f7561',
                status: 'Overdue',
                dateUpdated: '2024-06-11',
                openDate: '2024-06-10',
                dueDate: '2024-06-17',
                taskTemplate: {
                    taskType: TASK_TEMPLATE_ENUM.assessment,
                    title: 'Development assessment',
                    templateID: '07915789-a8f2-4130-bd8e-494c00b4c0c5',
                    status: 'Published',
                    completionType: 'Manager'

                },
            },
            {
                taskID: '818cf7f7-bd24-48b5-8771-ee6861734312',
                taskType: TASK_TEMPLATE_ENUM.assessment,
                title: 'Self Reflection 2022',
                assigneeOrgUserID: '02489b70-4e3b-42c2-abe7-4261549f7561',
                status: 'Completed',
                openDate: '2024-06-10',
                dueDate: '2024-06-17',
                taskTemplate: {
                    taskType: TASK_TEMPLATE_ENUM.assessment,
                    title: 'Development assessment',
                    templateID: '07915789-a8f2-4130-bd8e-494c00b4c0c5',
                    status: 'Published',
                    completionType: 'Staff'
                },
            },
            {
                taskID: '8ae8a918-c62b-47f3-8bdf-3726467bd4b6',
                taskType: TASK_TEMPLATE_ENUM.assessment,
                title: 'Check-in meeting',
                assigneeOrgUserID: '06e6c001-8b9b-42bb-a9fb-43a451515571',
                status: 'Completed',
                dateUpdated: '2024-06-12',
                openDate: '2024-06-10',
                dueDate: '2024-06-15',
                completeDate: '2024-06-17',
                taskTemplate: {
                    taskType: TASK_TEMPLATE_ENUM.assessment,
                    templateID: '3dd15a5e-566e-4767-8689-e75e2d64c2ad',
                    title: 'Self Reflection 2024',
                    status: 'Published',
                    completionType: 'Manager'
                },
            },
            {
                taskID: '8ae8a918-c62b-47f3-8bdf-3726467bd4c6',
                taskType: TASK_TEMPLATE_ENUM.meeting,
                title: 'Check-in meeting',
                assigneeOrgUserID: '06e6c001-8b9b-42bb-a9fb-43a451515571',
                status: 'Completed',
                openDate: '2024-06-10',
                dueDate: '2024-06-15',
                completeDate: '2024-06-17',
                taskTemplate: {
                    taskType: TASK_TEMPLATE_ENUM.meeting,
                    title: 'meeting 2030',
                    templateID: '3dd15a5e-566e-4767-8689-e75e2d64c2ad',
                    status: 'Published',
                    completionType: 'Both'
                },
            }
        ].map((a, i) => ({
            ...a,
            ...this.colService.getUserTableSimple(a.assigneeOrgUserID),
            cycle: MOCK_CYCLE_CONFIGS[i % 3],
            assignDate: a.openDate,
            // status: 'Completed'
        })) as any[]).pipe(delay(1000));
    }

    getMockTaskTemplates(): Observable<IPerformanceReviewTaskTemplate[]> {
        return of([
            {
                templateID: '2e099010-2954-4940-be92-e81e51fd44b5',
                taskType: TASK_TEMPLATE_ENUM.meeting,
                title: 'Development Meeting',
                status: 'Published',
                completionType: 'Manager',
                currentVersion: {
                    version: 1,
                    createDate: '2024-07-22',
                    createBy: '78a498fd-50f7-4d14-b1e4-4eda6b8485f1',
                    taskObjects: [
                        {
                            objectID: 'objID-1',
                            name: "object-name-1",
                            questions: [
                                {
                                    questionID: "questionID-1",
                                    // "objectID": "objID-1",
                                    qType: "short-answer",
                                    order: 1,
                                    text: "short answer 1",

                                },
                                {
                                    questionID: "questionID-2",
                                    "objectID": "objID-1",
                                    qType: "short-answer",
                                    order: 1,
                                    text: "short answer 2"
                                },
                                // {
                                //     questionID: "questionID-2",
                                //     "objectID": "objID-1",
                                //     qType: "short-answer",
                                //     order: 1,
                                //     text: "short answer 2"
                                // },
                                // {
                                //     questionID: "questionID-2",
                                //     "objectID": "objID-1",
                                //     qType: "short-answer",
                                //     order: 1,
                                //     text: "short answer 2"
                                // },
                                // {
                                //     questionID: "questionID-2",
                                //     "objectID": "objID-1",
                                //     qType: "short-answer",
                                //     order: 1,
                                //     text: "short answer 2"
                                // },
                            ]
                        }
                    ]
                }
            },
            {
                templateID: '07915789-a8f2-4130-bd8e-494c00b4c0c5',
                taskType: TASK_TEMPLATE_ENUM.meeting,
                title: 'Development Meeting',
                status: 'Published',
                description: 'Description here',
                completionType: 'Manager',

                "currentVersion": {
                    "version": 1,
                    "createDate": "2024-06-01T02:00:00Z",
                    "createBy": "4fc23061-b233-478d-829f-5f6142a9759d",
                    "taskObjects": []
                },

            },
            {
                taskType: TASK_TEMPLATE_ENUM.assessment,
                templateID: '3dd15a5e-566e-4767-8689-e75e2d64c2ad',
                title: 'Self Reflection 2024',
                status: 'Published',
                description: 'Description here',
                completionType: 'Manager',

                "currentVersion": {
                },
            }
        ] as any);
    }



}

export const MOCK_CYCLE_CONFIGS = [
    {
        cycleConfigID: '3066aff7-263d-4f14-af78-a7be1f2713d2',
        orgID: 'e76170da-5562-4cf7-9065-6afbfce77f9a',
        cycleType: CYCLE_TYPE_ENUM.annualDates,
        title: 'Annual Cycle Review',
        status: 'Active',
        openDay: 31,
        openMonth: 5,
        duration: '1 Year',
        description: 'description',
        activityNum: 10,
        enrolledUsers: 55,
        completionRate: 43
    },
    {
        cycleConfigID: '5c45eaec-a21f-4590-a4b4-46091501ba8c',
        orgID: 'e76170da-5562-4cf7-9065-6afbfce77f9a',
        cycleType: CYCLE_TYPE_ENUM.once,
        title: 'Once Cycle',
        status: 'Draft',
        openDay: 31,
        openMonth: 5,
        description: 'description',
        activityNum: 20,
        enrolledUsers: 360,
        completionRate: 87
    },
    {
        cycleConfigID: '56a4fe41-1628-460d-879f-27a56c6f0074',
        orgID: 'e76170da-5562-4cf7-9065-6afbfce77f9a',
        cycleType: CYCLE_TYPE_ENUM.annualEnrolment,
        title: 'Fixed Annual Cycle Review',
        status: 'Archived',
        duration: '1 Year',
        description: 'description',
        activityNum: 15,
        enrolledUsers: 98,
        completionRate: 94
    },
]


export function getAnswerText(task: IPerformanceReviewAssignFull, question: IPerformanceReviewTaskQuestion) {
    const response = task.response?.responses?.find(r => r.questionID === question.questionID);
    if (!response) {
        return '';
    }
    if (response.answerText) {
        return response.answerText;
    }
    if (!question.options?.length) {
        return ''
    }
    if (response.answerOption || response.answerOption === 0) {
        return question.options[+response.answerOption] || response.answerOption
    }
    if (response.multipleAnswers?.length) {
        return response.multipleAnswers.map(r => question.options[r]);
    }

}
