<app-general-report
    [filterEnables]="filterEnables"
    [pageContext]="pageContext"
    [dateFilterOptions]="{oneMonthRange: true}"
    (filterChange)="onFilterChange($event)"
    [hideDefaultSaveFilter]="true"
    [filterLabel]="{dateRange: 'Date Range'}"
>
    <ng-container heading>
        <h2 style="position: relative;" class="me-1">{{REPORT_CONFIG.title}}
            <app-tooltip [placement]="'right'"
                            [text]="REPORT_CONFIG.description"
                            placement="top" [tooltipClass]="'custom-tooltip'"></app-tooltip>
        </h2>
    </ng-container>

    <div class="pb-4">
      <ui-alert-banner copy="Ensure you validate your report with your internal training payment policy or protocol" alertType="warning"
        [includeDismiss]="false" type="New">
      </ui-alert-banner>
    </div>

    <!-- <app-shortcut-button-container class="d-flex justify-content-end"></app-shortcut-button-container> -->

    <app-multi-tab-tables [activeTabId]="0" [tables]="tables" (navChange)="activeTab = $event">
        <app-shared-table-actions [tableSettings]="tableSettingsArr[activeTab]" (download)="downloadCSV($event)"
        >
      </app-shared-table-actions>
    </app-multi-tab-tables>
    <app-smart-table
        [hidden]="activeTab !== 0"
        [source]="allTableSource"
        [settings]="allTableSettings"
        tableDiv="all-table"
        [withWrapper]="true"
        [stickyLeft]="true"
        [downloadSettings]="tableDownloadSettings"
    ></app-smart-table>
    <app-smart-table
        [hidden]="activeTab !== 1"
        [source]="byUserTableSource"
        [settings]="userTableSettings"
        tableDiv="user-table"
        [withWrapper]="true"
        [stickyLeft]="true"
        [downloadSettings]="tableDownloadSettings"
    ></app-smart-table>
    <app-smart-table
        [hidden]="activeTab !== 2"
        [source]="byTeamTableSource"
        [settings]="teamTableSettings"
        tableDiv="team-table"
        [withWrapper]="true"
        [stickyLeft]="true"
        [downloadSettings]="tableDownloadSettings"
    ></app-smart-table>
    <app-smart-table
        [hidden]="activeTab !== 3"
        [source]="byJobRoleTableSource"
        [settings]="jobTableSettings"
        tableDiv="job-table"
        [withWrapper]="true"
        [stickyLeft]="true"
        [downloadSettings]="tableDownloadSettings"
    ></app-smart-table>

</app-general-report>
