<div class="pt-7 px-7 pb-7 bg-white">
    <app-back-button [defaultBack]="true"></app-back-button>
    <h2 class="mt-2">
      <!-- <ui-streamline-icon class="me-2 text-pink-700" [slIcon]="titleIcon"></ui-streamline-icon> -->
      {{title}}
    </h2>
</div>
<div class="p-7 children-horizontal-gap-8">
    <div *ngFor="let group of cardGroups">
        <h4 class="mb-2 fw-semibold">{{group.groupTitle}}</h4>
        <p class="mb-2">{{group.groupDescription}}</p>
        <div class="d-flex flex-wrap gap-2">
            <ng-container *ngFor="let item of group.items">
                <app-overview-card *ngIf="reportCategory !== REPORT_CATEGORY.stakeholderReports" [item]="item"
                    class="card-reporting-size" textSizeClass="f-caption">
                    <ng-container customIcon>
                        <ui-avatar [slIcon]="item.reportSlIcon" class="mb-2" [iconBackgroundColor]="item.iconBgColor" [textColor]="'text-white'"></ui-avatar>
                    </ng-container>
                </app-overview-card>
                <app-advanced-card *ngIf="reportCategory === REPORT_CATEGORY.stakeholderReports" [item]="item"
                    class="card-reporting-size"></app-advanced-card>
            </ng-container>
        </div>
    </div>

</div>

