// recursively delete empty fields in a given object.
export function removeEmptyFields(obj) {
    Object.keys(obj).forEach(key => {
        if (obj[key] && typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
            removeEmptyFields(obj[key]);
        } else if (obj[key] === null || typeof obj[key] === 'undefined' || obj[key] === '' || (Array.isArray(obj[key]) && obj[key].length === 0)) {
            delete obj[key];
        }
    });
}

export function removeEmptyReturn(obj) {
    removeEmptyFields(obj);
    return obj;
}
