import { Component, Input, inject } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MultiSelectTableComponent } from '@components/multi-select-container/multi-select-table/multi-select-table.component';
import { SmartTableComponent } from '@components/smart-table/smart-table.component';
import { take } from 'rxjs/operators';
import { GroupService } from 'src/app/services/group.service';
import { SmartTableColumnsService } from 'src/app/services/smart-table-columns.service';
import { SmartTableService } from 'src/app/services/smart-table.service';
import { IOrgUser, USER_STATUS, UserService } from 'src/app/services/user.service';
import { LocalDataSource } from 'src/app/shared-modules/ng2-smart-table/lib/data-source/local/local.data-source';
import { keys, pluck } from 'underscore';
import { BaseStepComponent } from '../test';
import { orderBy } from 'lodash';

@Component({
  selector: 'app-select-direct-reports',
  standalone: true,
  imports: [MultiSelectTableComponent],
  templateUrl: './select-direct-reports.component.html',
  styleUrl: './select-direct-reports.component.scss'
})
export class SelectDirectReportsComponent extends BaseStepComponent<{manager}> {
  @Input() form: AbstractControl<string[]>; // orgUserIDs

  columnService = inject(SmartTableColumnsService);
  userService = inject(UserService);
  groupService = inject(GroupService);

  source = new LocalDataSource();
  settings = {
    selectMode: 'multi',
    actions: false,
    columns: this.columnService.getColumns([
    'user', 'staffID', 'accountStatus', 'email', 'teams', 'facilityModels', 'jobRoles'
  ], {local: true})}
  preselected: any;

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.groupService.fetchedCacheOrgTeams().pipe(take(1)).subscribe(() => {
      const data = orderBy(keys(this.userService.managedOrgUserDictionaryByOrgUserID)
        .filter(id => id !== this.allValues.manager)
        .map(id => this.columnService.getUserTableDetails(id))
        .filter(u => u.accountStatus !== USER_STATUS.inactive && u.accountStatus !== USER_STATUS.rejected)
        .filter(u => !u.user?.hierarchyTeamModel), ['accountStatus', 'user.fullName']);
      this.source.load(data);
    });
    this.preselected = this.form.value.map(id => this.userService.managedOrgUserDictionaryByOrgUserID[id])
  }

  onRowSelect(users: IOrgUser) {
    this.form.setValue(pluck(users, 'orgUserID'));
  }



}
