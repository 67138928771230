import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, share, tap, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { omit } from 'underscore';
import { IAckBackend, PolicyFromBackend } from 'src/app/pages/policies-procedures/interfaces/policy-and-procedure.interface';
import { ErrorHandlingService } from '../services/error-handling.service';
import { BroadcastService } from './broadcast.service';
import { TitleCellComponent } from '../pages/policies-procedures/table-cells/pp-title-cell.component';
import { CustomColumnFilterComponent } from '@components/custom-column-filter/custom-column-filter.component';
import { StaffLinkDisplayComponent } from '@components/staff-link-display/staff-link-display.component';
import { TruncatedCellComponent } from '@components/truncated-cell/truncated-cell.component';
import { TableColumnValuePrepareAndSortUtilsService } from './table-column-value-prepare-and-sort-utils.service';
import { AccountStatusPipe } from '../shared/pipes/account-status.pipe';
import { LocalDatePipe } from '../shared/pipes/local-date.pipe';
import { StaffStatusDisplayComponent } from '@components/staff-status-display/staff-status-display.component';
import { TeamNamesCellComponent } from '@components/learning-table-popovers/team-names-cell.component';
import { JobRoleTitlesCellComponent } from '@components/learning-table-popovers/jobrole-titles-cell.component';
import { PPSharedActionsButtonComponent } from '../pages/policies-procedures/table-cells/pp-shared-actions-button/pp-shared-actions-button.component';
import { capitalize as _capitalize } from 'lodash';
import { SmartTableService } from './smart-table.service';


@Injectable({
  providedIn: 'root'
})
/**
 * service for policy and procedure feature
 */
export class PnpService {
  docs = new BehaviorSubject(null);
  publishedDocs = new BehaviorSubject(null);
  private pendingDocs = false;

  policies = new BehaviorSubject<PolicyFromBackend[]>(null);
  private pendingPolicies = false;

  private _ackUpdate$ = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private errorHandlingService: ErrorHandlingService,
    private toaster: ToastrService,
    private broadcastService: BroadcastService,
    private _columnUtils: TableColumnValuePrepareAndSortUtilsService,
    private accountStatusPipe: AccountStatusPipe,
    private localDatePipe: LocalDatePipe,

  ) {
    this.broadcastService.on('logout').subscribe(() => {
      this.docs.next(null);
      this.publishedDocs.next(null);
      this.policies.next(null);
    });
  }

  addForm(pnpForm: IPnpForm) {
    return this.http.post(environment.accountServiceEndpoint + `/orgs/${localStorage.getItem('orgID')}/governances/docs`, pnpForm).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  editForm(existingForm: IDocResponse) {
    return this.http.put(environment.accountServiceEndpoint + `/orgs/${localStorage.getItem('orgID')}/governances/docs/${existingForm.docID}`,
      omit(existingForm, 'docID')).pipe(
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  // generalHttpHandle(request: Observable<any>, toastMessage = 'Success') {
  //   request.subscribe(() => {
  //     this.toaster.success(toastMessage);
  //     this.modal.close();
  //   }, error => {

  //   })
  // }

  fetchCachedDocs(refresh?: boolean): Observable<any> {
    if (refresh || (!this.docs.value && !this.pendingDocs)) {
      this.pendingDocs = true;
      const fetch$ = this.fetchForms().pipe(
        tap(() => this.pendingDocs = false),
        catchError((e) => {
          this.pendingDocs = false;
          return throwError(e);
        }),
        share(),
      );
      fetch$.subscribe();
    }
    return this.docs.pipe(filter(x => !!x));
  }

  fetchCachedPublishedDocs(refresh?: boolean): Observable<any> {
    if (refresh || (!this.publishedDocs.value && !this.pendingDocs)) {
      this.pendingDocs = true;
      const fetch$ = this.fetchForms().pipe(
        tap(() => this.pendingDocs = false),
        catchError((e) => {
          this.pendingDocs = false;
          return throwError(e);
        }),
        share(),
      );
      fetch$.subscribe();
    }
    return this.publishedDocs.pipe(filter(x => !!x));
  }


  fetchForms() {
    const url = `${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/governances/docs`;
    return this.http.get(url).pipe(
      tap((forms: any) => {
        this.docs.next(forms);
        this.publishedDocs.next(forms.filter(f => f.status === 'Published'));
      }),
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  fetchPPDocByID(docID) {
    const url = `${environment.resourceReadApiHost}/orgs/${localStorage.getItem('orgID')}/governances/docs/${docID}`;
    return this.http.get(url).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  searchDoc(param, searchFilter):Observable<IDocResponse[]> {
    const url = `${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/governances/docs/search?${param}`;
    return this.http.post<IDocResponse[]>(url, searchFilter).pipe(
      catchError(this.errorHandlingService.handleHttpError)
    );
  }

  fetchAckByPolicy(policyID): Observable<{ gov: any; acks: IAckBackend[] }> {
    const orgID = localStorage.getItem('orgID');
    const url = `${environment.accountServiceEndpoint}/orgs/${orgID}/govs/${policyID}/acks`;
    return this.http.get<{ gov: any; acks: IAckBackend[] }>(url).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  fetchAllPolicies(): Observable<PolicyFromBackend[]> {
    const orgID = localStorage.getItem('orgID');
    const url = `${environment.resourceReadApiHost}/orgs/${localStorage.getItem('orgID')}/governances/search?_page=1&_limit=1000`;
    return this.http.post<PolicyFromBackend[]>(url, {
      governanceType: ['policy'],
      orgID
    }).pipe(
      catchError(this.errorHandlingService.handleHttpError),
      tap(pols => this.policies.next(pols))
    );
  }

  fetchCachedAllPolicies(refresh = false): Observable<PolicyFromBackend[]> {
    if (refresh || (!this.policies.value && !this.pendingPolicies)) {
      this.pendingPolicies = true;
      const fetch$ = this.fetchAllPolicies().pipe(
        tap(() => this.pendingPolicies = false),
        catchError((e) => {
          this.pendingPolicies = false;
          return throwError(e);
        }),
        share(),
      );
      fetch$.subscribe();
    }
    return this.policies.pipe(filter(x => !!x));

  }

  searchPoliciesSSR(keywords, filter = {}, condition = 'and'): Observable<PolicyFromBackend> {
    const orgID = localStorage.getItem('orgID');
    const url = `${environment.resourceReadApiHost}/orgs/${localStorage.getItem('orgID')}/governances/search?_page=1&_limit=10000&condition=${condition}&title_like=${keywords}`;
    return this.http.post<PolicyFromBackend>(url, {
      ...filter,
      governanceType: ['policy'],
      orgID
    }).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );

  }

  deleteDoc(docID: string) {
    const url = `${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/governances/docs/${docID}`;
    return this.http.delete(url).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );

  }

  get ackUpdate$(): Observable<any> {
    return this._ackUpdate$.asObservable();
  }

  announceAckUpdate() {
    this._ackUpdate$.next(null);
  }

  uploadGovFile(file, orgID, options?): Observable<any> {
    return this.http.post(environment.uploadEndpoint + `/orgs/${orgID}/govFiles`, file, options)
      .pipe(
        timeout(60000),
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  uploadGovDocFile(file, orgID, options?): Observable<any> {
    return this.http.post(environment.uploadEndpoint + `/orgs/${orgID}/govDocFiles`, file, options)
      .pipe(
        timeout(60000),
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  columns =  {
    fn_title: {
      title: 'Item Title',
      // isSticky: true, // exempt from edit column
      // valuePrepareFunction: (title) => {
      //     return _capitalize(title);
      // },
      type: 'custom',
      isSticky: true,
      sort: false,
      renderComponent: TitleCellComponent,
      // onComponentInitFunction: (instance) => {
      //   instance.isNewTab = true;
      //  },
      filter: {
        type: 'custom',
        component: CustomColumnFilterComponent
      }
    },
    fn_status: {
      title: 'Acknowledgement Status',
      sort: false,
      // type: 'custom',
      // renderComponent: PPAcknowledgementStatusCellComponent,
      filter: {
        type: 'custom',
        component: CustomColumnFilterComponent
      },
      valuePrepareFunction: (status) => {
        return _capitalize(status);
      },
    },
    fn_govCode: {
      title: 'Item ID',
      sort: false,
      filter: {
        type: 'custom',
        component: CustomColumnFilterComponent
      },
      valuePrepareFunction: (val) => val || '-'
    },
    fn_govVersionCode: {
      title: 'Version No.',
      sort: false
    },
    fn_govStatus: {
      title: 'Item Status',
      sort: false,
      filter: {
        type: 'custom',
        component: CustomColumnFilterComponent
      },
    },
    orgUser: {
      title: 'User',
      type: 'custom',
      filter: {
        type: 'custom',
        component: CustomColumnFilterComponent
      },
      renderComponent: StaffLinkDisplayComponent,
      onComponentInitFunction: (instance) => {
        instance.fragment = 'Policies';
      },
      sort: false,
    },
    fn_lastRemind: {
      title: 'Last Reminded',
      type: 'string',
      valuePrepareFunction: (date) => {
        return this.localDatePipe.transform(date);
      },
      sort: true,
      filter: false
    },
    staffID: {
      title: 'User ID',
      sort: false,
      filter: true,
      type: 'custom',
      valuePrepareFunction: this._columnUtils.replaceNoRecord(),
      renderComponent: TruncatedCellComponent,
      onComponentInitFunction: (instance) => {
        instance.className = 'staffID';
      },
    },
    fn_assignDate: {
      title: 'Date Assigned',
      sort: true,
      filter: false,
      type: 'string',
      valuePrepareFunction: (date) => {
        return this.localDatePipe.transform(date);
      },
    },
    fn_assignDays: {
      title: 'Days Assigned',
      sort: false,
      filter: false
    },
    fn_ackDate: {
      title: 'Date Acknowledged',
      type: 'string',
      valuePrepareFunction: (date) => {
        return this.localDatePipe.transform(date);
      },
      sort: true,
      filter: false
    },

    userStatus: {
      title: 'Account Status',
      filter: {
        type: 'custom',
        component: CustomColumnFilterComponent
      },
      sort: false,
      type: 'custom',
      valuePrepareFunction: (val) => this.accountStatusPipe.transform(val),
      renderComponent: StaffStatusDisplayComponent,
    },
    email: {
      title: 'Email',
      type: 'custom',
      sort: false,
      renderComponent: TruncatedCellComponent,
      onComponentInitFunction(instance) {
          instance.className = 'email';
      },
      },
      orgMobile: { ...inject(SmartTableService).tableSettings.orgMobile, defaultToShow: false, sort: false, filter: false, },
    teamNames: {
      title: 'Teams',
      type: 'custom',
      renderComponent: TeamNamesCellComponent,
      valuePrepareFunction: this._columnUtils.extractByProperty('name'),
      filterFunction: this._columnUtils.searchByProperty('name'),
      sort: false,
      filter: {
        type: 'custom',
        component: CustomColumnFilterComponent
      },
      defaultToShow: true,
    },
    jobRoleTitles: {
      title: 'Job Roles',
      sort: false,
      filter: {
        type: 'custom',
        component: CustomColumnFilterComponent
      },
      type: 'custom',
      defaultToShow: true,
      renderComponent: JobRoleTitlesCellComponent,
      valuePrepareFunction: this._columnUtils.extractByProperty('title'),
      filterFunction: this._columnUtils.searchByProperty('title')
    },
    actions: {
      title: 'Actions',
      type: 'custom',
      sort: false,
      exemptFromEdit: true,
      filter: false,
      renderComponent: PPSharedActionsButtonComponent,
      onComponentInitFunction: (instance) => {
        instance.separateViewButton = true;
        instance.btnWrapperClasses = 'justify-content-end';
        instance.triggerPage = 'acknowledgementRecordsTableAction';
      },
    }

};

}



export interface IPnpForm {
  orgID: string;
  name: string;
  docCode?: string;
  description?: string;
  url: string;
  status?: string;
}

export interface IPnpFormExisting extends IPnpForm {
  docID: string;
}

export interface IPnpProcedure {
  governanceID;
  orgID: string;
  title: String;
  governanceType: String;
  code?: String;
  description?: String;
  governanceVersion: any;
}


export interface IDocResponse {
  topics: any[];
  standards: any[];
  orgStandards?: any[];
  docID: string;
  orgID: string;
  name?: string;
  docCode?: string;
  url: string;
  description?: string;
  createDate: string;
  updateDate: string;
  createBy?: string;
  updatedBy?: string;
  status: string;
  linkedGovernance?: string[];
}

export interface IFormBackend {
  name: string;
  status: string;
  docCode: string;
  updateDate: string;
}




