import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';
import { ShortcutButtonContainerComponent } from 'src/app/pages/reporting/filter-pack/shortcut-button-new/shortcut-button-container/shortcut-button-container.component';

@Component({
  selector: 'app-download-csv-button',
  standalone: true,
  imports: [CommonModule, UIStreamlineIconComponent, ShortcutButtonContainerComponent],
  templateUrl: './download-csv-button.component.html',
  styleUrls: ['./download-csv-button.component.scss']
})
export class DownloadCsvButtonComponent implements OnInit {
  @Output() buttonClick = new EventEmitter()
  @Input() withSaveFilter = false;
  @Input() text = 'Download CSV';

  constructor() { }

  ngOnInit(): void {
  }

  onClick() {
    this.buttonClick.emit();
  }
}
