import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { DOCUMENT, NgIf, NgClass } from '@angular/common';
import { AccountStatusPipe } from '../../pipes/account-status.pipe';
import { StaffStatusDisplayComponent } from '../staff-status-display/staff-status-display.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';
import { AvatarComponent } from '@components/avatar/avatar.component';

export interface ISingleSelectOption<T = string> {
  label: string;
  rightLabel?: string;
  caption?: string;
  value: T;
  child?: boolean;
  borderBottom?: boolean;
  addL?: boolean;
  level?: number;
  imgUrl?: string;
}

@Component({
  selector: 'app-customised-single-select',
  templateUrl: './customised-single-select.component.html',
  styleUrls: ['./customised-single-select.component.scss'],
  standalone: true,
  imports: [NgIf, NgSelectModule, NgClass, FormsModule, ReactiveFormsModule, StaffStatusDisplayComponent, AccountStatusPipe, UIStreamlineIconComponent, AvatarComponent]
})
export class CustomisedSingleSelectComponent implements OnInit, AfterViewInit {
  @Input() id: string;
  @Input() controls;
  @Input() controlName: string;
  @Input() options: ISingleSelectOption[];
  @Input() displayName: string;
  @Input() searchable = true;
  @Input() placeholder;
  @Input() submitted;
  @Input() autoGrow = false;
  @Input() slIcon: UIStreamlineIconComponent;
  @Input() showAvatar = false;
  @Output() selectorBlur = new EventEmitter();

  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
  }

  onOpenDropdown() {
    setTimeout(() => {
      const optionWithBorder = this.document.querySelector('.parent-border')?.parentElement;
      if (optionWithBorder) {
        this.renderer.addClass(optionWithBorder, 'border-bottom');
        this.renderer.addClass(optionWithBorder, 'py-3');
      }
    });

  }


}
