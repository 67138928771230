export enum LocalTableUniqKey {
  tslTopBottom = 'app_time_spent_learning_top_bottom',
  sgrLearningRecords = 'app_shared_learning_records_1669761439929',
  sgrLearningByUser = 'app_shared_learning_by_user_1669761439929',
  sgrLearningByResource = 'app_shared_learning_by_resource_1669761439929',
  sgrReportByStandards = 'app_report_by_standards',
  sgrComplianceRecords = 'app_compliance_records_1682312427853',
  sgrComplianceRecordsModal = 'app_compliance_records_modal_1669761439929',
  sgrExtendedRecords = 'app_extended_records_1669761439929',
  sgrComplianceRecordsByPlan = 'app_compliance_records_by_plan_1669761439929',
  sgrComplianceRecordsByUser = 'app_compliance_records_by_user_1669761439929',
  sgrComplianceRecordsByResource = 'app_compliance_records_by_resource_1669761439929',
  sgrComplianceRequirementsNumModal = 'app_compliance_records_num_modal_1669761439929',
  sgrComplianceRequirementsNumModalOpen = 'app_compliance_records_num_modal_open_1669761439929',
  sgrComplianceRequirementsNumModalOverdue = 'app_compliance_records_num_modal_overdue_1669761439929',
  sgrComplianceRequirementsNumModalMissed = 'app_compliance_records_num_modal_missed_1669761439929',
  sgrComplianceRequirementsNumModalCompleted = 'app_compliance_records_num_modal_Completed_1669761439929',
  sgrComplianceRequirementsNumModalExtended = 'app_compliance_records_num_modal_extended',
  sgrAllFeedback = 'app_sgr_all_feedback',
  sgrFeedbackByUser = 'app_sgr_feedback_by_user',
  sgrFeedbackByResource = 'app_sgr_feedback_by_resource',
  sgrFeedbackNumModal = 'app_sgr_feedback_num_modal',
  sgrAssignLearningReport = 'app_sgr_assign_learning_report_1669761439929',
  sgrAssignLearningByStaff = 'app_sgr_assign_learning_by_staff_1669761439929',
  sgrAssign2 = 'app_sgr_assign_2_1669761439929',
  sgrOptionalAssignOpen = 'app_sgr_assign_2_open',
  sgrOptionalAssignRemoved = 'app_sgr_assign_2_removed',
  sgrOptionalAssignRemovedModal = 'app_sgr_assign_2_removed_modal',
  sgrOptionalAssignCompleted = 'app_sgr_assign_2_completed',
  sgrAssign2Staff = 'app_sgr_assign_2_staff_1669761439929',
  sgrAssign2Resource = 'app_sgr_assign_2_resource_1669761439929',
  recommendedByStaff = 'app_recommended_by_staff_1669761439929f',
  manageStaffAll = 'app_manage_staff_all',
  manageStaffActive = 'app_manage_staff_active',
  manageStaffPending = 'app_manage_staff_pending',
  manageStaffInvited = 'app_manage_staff_invited',
  manageStaffInActive = 'app_manage_staff_inactive',
  staffTranscriptAllRequirements = 'app_individual_all_requirements_report',
  staffTranscriptMandatoryRequirements = 'app_individual_mandatory_requirements_report',
  staffTranscriptOptionalRequirements = 'app_individual_optional_requirements_report',
  staffTranscriptCompliance = 'app_individual_compliance_report_1669761439929',
  staffTranscriptByAchieved = 'app_individual_compliance_by_achieved_1669761439929',
  staffTranscriptByUnachieved = 'app_individual_compliance_by_unachieved_1669761439929',
  staffTranscriptByOverdue = 'app_individual_compliance_by_overdue_1669761439929',
  staffTranscriptByOpen = 'app_individual_compliance_by_open_1669761439929',
  staffTranscriptByScheduled = 'app_individual_compliance_by_scheduled_1669761439929',
  staffTranscriptLearningRecords = 'app_individual_learning_records_1669761439929',
  staffAcknowledgementRecords = 'app_individual_acknowledgement_records',
  complianceImpactModal = 'app_compliance_impact_modal_1669761439929',
  optionalImpactModal = 'app_optional_impact_modal',
  linkedLearningRecordsModal = 'app_linked_learning_modal_1669761439929',
  learningRecordsNumModal = 'app_learning_records_num_modal_1669761439929',
  teamMemberPending = 'app_team_member_pending',
  teamMemberInvited = 'app_team_member_invited',
  teamMemberActive = 'app_team_member_active',
  teamMemberInactive = 'app_team_member_inactive',
  addTeamModal = 'app_add_team_modal',
  addJobModal = 'app_add_job_modal',
  jobMemberPending = 'app_job_member_pending',
  jobMemberInvited = 'app-job-member-invited',
  jobMemberActive = 'app_job_member_active',
  jobMemberInactive = 'app_job_member_inactive',
  assignJob = 'app_assign_job',
  ausmedLibrary = 'app_ausmed_library',
  yourLibrary = 'app_your_library',
  nzPartnerLibrary = 'nz_partner_library',
  resourceDetailsLearningRecords = 'app_resource_details_records_1669761439929',
  resourceDetailsByUser = 'app_resource_details_by_user',
  metricModal = 'app-metric-modal',
  metricModalFailed = 'app-metric-modal-failed',
  metricModalStarted = 'app-metric-modal-started',
  metricModalProgress = 'app-metric-modal-progress',
  metricModalComplete = 'app-metric-modal-complete',
  courseResponseModal = 'app-curse-response-modal',
  workroomAssigned = 'app_workroom_assigned',
  workroomOverdue = 'app_workroom_overdue',
  workroomIncomplete = 'app_workroom_incomplete',
  workroomScheduled = 'app_workroom_scheduled',
  planUser = 'app_plan_user',
  planResource = 'app_plan_resource',
  planJobRole = 'app_plan_jobrole',
  planTeam = 'app_plan_team',
  planOverdue = 'app_plan_overdue',
  planMatrix = 'app_plan_matrix',
  planMatrixDisplay = 'app_plan_matrix_display',
  viewRequirementModal = 'app_view_requirement_modal',
  b2bInvoices = 'app_b2b_invoices',
  proceduresList = 'app-procedures-list-table',
  ppDocs = 'pp_docs',
  ppVersions = 'pp-versions',
  ppDraft = 'pp-draft',
  ppReview = 'pp-review',
  ppDetailAck = 'pp-detail-ack',
  ppDetailUnack = 'pp-detail-unack',
  ppDetailOther = 'pp-detail-other',
  ppDetailAll = 'pp-detail-all',
  ppNonCompliantUsers = 'pp-dashboard-non-compliant',
  policyMng = 'policy-manage',
  learningDashboardNonCompliantUsers = 'learning-dashboard-non-compliant',
  sgrAckRecords = 'pp-ack-records',
  sgrAckRecordsMng = 'pp-ack-records-mng',
  manageTeams = 'app-manage-team',
  manageJobRoles = 'app-manage-job-role',
  calendarMissed = 'app-calendar-missed-assigns',
  calendarOverdue = 'app-calendar-overdue-assigns',
  calendarOpen = 'app-calendar-open-assigns',
  calendarCompleted = 'app-calendar-completed-assigns',
  calendarAssigned = 'app-calendar-assigned-assigns',
  calendarSummary = 'app-calendar-summary',
  orgStructureTable = 'org-structure-table',
  orgStructureTableModal = 'org-structureTable-modal-table',
  scormSchedule = 'app_scorm-schedule',
  facilityUserModal = 'facility-user-modal',
  payrollAll = 'payroll-all',
  payrollUser = 'payroll-user',
  payrollTeam = 'payroll-team',
  payrollJob = 'payroll-job',
  payrollModal = 'payroll-modal',
  reqAll = 'req-all',
  reqSidePanel = 'req-side-panel',
  reqbyPlan = 'req-plan',
  reqByUser = 'req-user',
  reqResource = 'req-resource',
  facilityMember = 'facility-member',
  itemManagement = 'item-management_1701051614486',
  performanceReviewTaskTemplates = 'performance-review-task-templates',
  performanceReviewActivitiesDashboard = 'performance-review-activities-dashboard',
  performanceReviewGoalsDashboard = 'performance-review-goals-dashboard',
  performanceReviewCyclesDashboard = 'performance-review-cycles-dashboard',
  performanceReviewCycleConfigs = 'performance-review-cycle-configs',
  performanceReviewTaskConfigs = 'performance-review-task-configs',
  performanceReviewEnrolmentUsers = 'performance-review-enrolment-users',
  performanceReviewProgressTable = 'performance-review-progress-table',
  hierarchy = 'hierarchy',
  progressOverdue = 'progress-overdue',
  progressCompleted = 'progress-completed',
  progressOpen = 'progress-open',
  progressScheduled = 'progress-scheduled',
  progressAll = 'progress-all',
  progressUserCompleted = 'progress-user-completed',
  progressUserOngoing = 'progress-user-ongoing',
  progressUserAll = 'progress-user-all',
  progressCycle = 'progress-cycle',
  progressCycleSingleAll = 'progress-cycle-all',
  progressCycleSingleCompleted = 'progress-cycle-completed',
  progressCycleSingleOpen = 'progress-cycle-open',
  progressCycleSingleOverdue = 'progress-cycle-overdue',
  progressModal = 'progress-modal'
}
