<div class="pt-7 px-7 pb-3 border-bottom" style="background-color: white;">
    <app-back-button [defaultBack]="true" class="mb-2"></app-back-button>
    <ng-content select="[heading]"></ng-content>
    
    <div class="mt-4">
    <app-filter-pack *ngIf="initFilter" [filterEnables]="filterEnables"
        [spinnerButtonDone]="isDataFetched"
        (filterSubmit)="filterTypeHandler($event)"
        [runReportOnInit]="runReportOnInit"
        [displayStatusDict]="displayStatusDict"
        [initMandatoryTypes]="initMandatoryTypes"
        [dateFilterOptions]="dateFilterOptions"
        [filterParams]="filterParams"
        [label]="filterLabel"
        [alwaysShowResource]="filterParams?.alwaysShowResource"
        [altResourcePosition]="filterParams?.altResourcePosition"
        >
        <ng-container extraFilter>
            <ng-content select="[extraFilter]"></ng-content>
        </ng-container>
        
        
    </app-filter-pack>

    </div>
</div>

<div [class]="contentClass">
    <ng-container [ngSwitch]="spinnerButtonStatus">
        <!-- <div class="mb-2" *ngSwitchCase="SPINNER_BUTTON_STATUS_ENUM.after" [@isAlertDisplay]="isAlertDisplay ? 'open' : 'closed'">
            <ui-alert-banner type="New" alertType="success" copy="Results updated" [includeDismiss]="false"></ui-alert-banner>
        </div> -->
        <div class="mb-2" *ngSwitchCase="SPINNER_BUTTON_STATUS_ENUM.reset">
            <ui-alert-banner type="New" alertType="warning" copy="Your query has changed, please run report again to update the results below" [includeDismiss]="false"></ui-alert-banner>
        </div>
        <div class="mb-2" *ngSwitchCase="SPINNER_BUTTON_STATUS_ENUM.resetToDefault">
            <ui-alert-banner type="New" alertType="warning" copy="Your query has been reset to default, please run report again to update the results below" [includeDismiss]="false"></ui-alert-banner>
        </div>
    </ng-container>



    <ng-template #prompt>
        <ui-empty-state content="Make selections above and click Run Report to view the results"></ui-empty-state>
    </ng-template>

    <div *ngIf="filterSubmitted; else prompt" style="position: relative!important; min-height: 80vh;">
        <ngx-spinner bdColor="#FAFAFB" color="#7F71D5" type="none" name="sgr-spinner" [fullScreen]="false">
            <ui-spinner></ui-spinner>
        </ngx-spinner>
        <ng-container *ngIf="filterSubmitted; else setConditionsToProceed">

            <div [hidden]="!isDataFetched">
                <div class="position-relative">
                    <ng-content></ng-content>
                    <!-- <div *ngIf="!hideDefaultSaveFilter" class="btn-default-shortcut">
                        <app-shortcut-button-container></app-shortcut-button-container>
                    </div> -->
            </div>
            </div>
        </ng-container>
        <ng-template #setConditionsToProceed>
            <ui-empty-state content="Make selections above and click Run Report to view the results"></ui-empty-state>
        </ng-template>
    </div>
</div>
