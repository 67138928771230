<div class="d-flex justify-content-center">
    <div class="p-7 overview-container">
        <h1 class="pb-4">{{title}}</h1>
        <app-overview-info-card
            *ngIf="cardBoldedText || cardText"
            [boldedText]="cardBoldedText"
            [text]="cardText"
        ></app-overview-info-card>

        <div *ngIf="shortcutType" class="mt-8 mb-8">
            <app-overview-shortcuts [shortcutType]="shortcutType"></app-overview-shortcuts>
        </div>
            

        <div class="grid-halfs mt-4 gap-2">
            <ng-container *ngFor="let item of items">
                <ng-container *featureFlag="item.featureFlag">
                    <app-overview-card *ngIf="!item.hide" [item]="item"></app-overview-card>
                </ng-container>
            </ng-container>
        </div>
        <ng-content></ng-content>
    </div>

</div>
