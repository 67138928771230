import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { cacheResponse2 } from '../shared/utils/rx/cache-response-2';
import { ErrorHandlingService } from './error-handling.service';
import { HttpClient } from '@angular/common/http';
import { catchError, filter, map, share, shareReplay, tap, timeout, take, mergeMap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject, throwError } from 'rxjs';
import * as _ from 'underscore';
import { Dictionary, contains, flatten, groupBy, includes, indexBy, mapObject, pluck, sortBy, values } from 'underscore';
import { decode } from 'he';
import { IOrgResource, RESOURCE_TYPE_ENUM } from '../shared/byo-shared/byo-resource-types';
import { BroadcastService } from './broadcast.service';
import { FeatureFlagService } from './feature-flag.service';
import { FEATURES } from '../core/features.config';
import { getByoResourceTypeByResource } from '../shared/utils/get-resource-type';
import { FRAMEWORKS_ENUM } from '../pages/settings/interfaces/IOrgLMSSetting';
import { OrganisationService } from './organisation.service';
import { PRODUCT_TYPES } from '../core/guards/mode.constants';
import { OrgResourceService } from './org-resource.service';
import { trim } from 'lodash';
import { DataCacher } from '../shared/utils/class/DataCacher';


@Injectable({
  providedIn: 'root'
})
export class ResourceService {
  isAusmedLMSFreeUser = this.organisationService.isContractType(PRODUCT_TYPES.LMS_FREE);
  // Management content about Ausmed LMS and 3rd party resources
  thirdPartyOnlyParams = this.organisationService.isContractType(PRODUCT_TYPES.LMS_FREE) ? '?thirdPartyOnly=true' : '';


  latestNzacResources$ = this.http
    .post<any[]>(`${environment.resourceReadApiHost}/orgs/${localStorage.getItem('orgID')}/orgResources/search/partner?page=1&limit=20`, {})
    .pipe(
      shareReplay(1),
      // map((resources) => {
      //   if (this.organisationService.isContractType(PRODUCT_TYPES.LIBRARY)) {
      //     return resources.filter(r => ['onlineResource', 'explainer', 'blog', 'OnlineCourse'].indexOf(r.activityType) > -1);
      //   } else {
      //     return resources;
      //   }
      // }),
      catchError(this.errorHandlingService.handleHttpError)
    );
  orgResources = new BehaviorSubject(null);
  private pendingOrgCourse: boolean;
  orgCourse = new BehaviorSubject(null);
  guidelines = new BehaviorSubject(null);
  guidelineDict: Dictionary<any>;
  guidelineDictByProvider: Dictionary<any>;
  allResourcesDictByResourceID: Dictionary<any>;
  private _allResourcesCacher: DataCacher<any[]> = new DataCacher<any[]>(this.getAllActiveResources(), this.broadcastService);

  allResourcesDictByResourceCode: Dictionary<any>;
  filterPackResourceDic$ = new ReplaySubject<Dictionary<any>>(1) ; // initialised by filter pack!!

  allBundles = new BehaviorSubject<Bundle[]>(null);
  private pendingBundles = false;

  resourceDict = {
    onlineResource: 'Lecture',
    blog: 'Article',
    OnlineCourse: 'Course',
    onlineCourse: 'Course',
    explainer: 'Explainer',
    'e-Learning': 'E-Learning',
    guideToPractice: 'Guides to Practice',
  };

  resourceGuideDict = {
    onlineResource: 'nursingLectureDetail',
    blog: 'blogDetail',
    OnlineCourse: 'courseDetail',
    explainer: 'explainerDetail'
  };



  topicDictionary: Dictionary<any>;

  topics = new BehaviorSubject(null);
  topicStore: any[] = [];

  categories = new BehaviorSubject([]);
  categoryStore: any[] = [];

  providers = new BehaviorSubject([]);
  providerStore: any[] = [];

  featuredResources = new BehaviorSubject([]);
  featuredResourceStore: any[] = [];

  pendingGuidelines = false;

  pendingTopics = false;

  purchasedResourceIDs = undefined;

  purchasedResources = undefined;

  fetchAusmedResources = cacheResponse2(() => {
    return this.http
      .get(`${environment.resourceReadApiHost}/resources/simple`)
      .pipe(
        map((resources: any) => {
          if (this.featureFlag.featureOn(FEATURES.ausmedLibrary)) {
            return resources.map((resource) => {
              resource.title = decode(resource.title);
              return resource;
            }
            );
          } else {
            return [];
          }
        }
        ),
        catchError(this.errorHandlingService.handleHttpError));
  });

  fetchThirdPartyResources = cacheResponse2(() => {
    return this.http
      .get(`${environment.resourceReadApiHost}/thirdPartyResources/active`)
      .pipe(
        map((resources) => {
          if (this.featureFlag.featureOn(FEATURES.ausmedLibrary)) {
            return resources;
          } else {
            return [];
          }
        }), catchError(this.errorHandlingService.handleHttpError),
      );
  });

  fetchPartnerResources = cacheResponse2(() => {
    return this.http
      .get(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/partnerResources`)
      .pipe(
        map((resources: any[]) => {
          if (this.featureFlag.featureOn(FEATURES.partnerContent)) {
            return resources;
          } else {
            return [];
          }
        }),
        catchError(this.errorHandlingService.handleHttpError),
      );
  });

  fetchThirdPartyProvidersWithResCount = cacheResponse2((): Observable<IProvider[]> => {
    return this.http
      .get(environment.resourceReadApiHost + '/providers/active/resCount' + this.thirdPartyOnlyParams)
      .pipe(
        map((providers: any) => providers.filter(p => p.isContentPartner)),
        catchError(this.errorHandlingService.handleHttpError),
      );
  });

  fetchCategories = cacheResponse2((): Observable<ICategoryWithResCount[]> => {
    return this.http
      .get<ICategoryWithResCount[]>(environment.resourceReadApiHost + '/categoriesWithResCount' + this.thirdPartyOnlyParams)
      .pipe(
        tap((categories: any) => {
          if (categories.length > 0) {
            this.categoryStore = this.categoryStore.concat(categories);
            this.categoryStore = _.uniq(this.categoryStore, (category) => category.categoryID);
            this.categories.next(this.categoryStore);
          }
        }),
        catchError(this.errorHandlingService.handleHttpError),
      );
  });

  fetchProfessionsWithResCount = cacheResponse2((professionNames: string[]): Observable<IProfessionWithResCount[]> => {
    return this.http
      .post<IProfessionWithResCount[]>(environment.resourceReadApiHost + '/resources/professionsWithResCount' + this.thirdPartyOnlyParams, professionNames)
      .pipe(
        catchError(this.errorHandlingService.handleHttpError),
      );
  });

  getAllActiveResourceID() {
    return combineLatest([
      this.fetchAusmedResources(),
      this.orgResourceService.fetchCachedOrgActiveResources(),
      this.fetchThirdPartyResources()
    ]).pipe(
      take(1),
      map(([ausmedResources, thirdResources, orgResources]) => {
        return ausmedResources.concat(ausmedResources, thirdResources, orgResources);
      }),
      map(allResources => pluck(allResources, 'resourceID'))
    );
  }

  fetchCachedAllResources(refresh = false): Observable<any[]> {
    return this._allResourcesCacher.fetchCachedData(refresh)
  }

  getAllActiveResources() {
    return combineLatest([
      this.fetchResources(1, 1000000),
      this.orgResourceService.fetchCachedOrgActiveResources(),
    ]).pipe(
      take(1),
      map(([ausmedAndThirdResources, orgResources]) => {
        this.allResourcesDictByResourceCode = { ...indexBy(orgResources.map(r => ({ ...r, code: trim(r.orgResourceDetail?.code) })), 'code'), ...indexBy(ausmedAndThirdResources, 'code') };
        delete this.allResourcesDictByResourceCode['undefined'];
        return ausmedAndThirdResources.concat(orgResources);
      }),
      tap(res => {
        this.allResourcesDictByResourceID = indexBy(res, 'resourceID');
      }),
    );
  }

  constructor(
    private http: HttpClient,
    private broadcastService: BroadcastService,
    private errorHandlingService: ErrorHandlingService,
    private featureFlag: FeatureFlagService,
    private organisationService: OrganisationService,
    private customErrorHandler: ErrorHandlingService,
    private orgResourceService: OrgResourceService,
  ) {
    this.broadcastService.on('logout').subscribe(() => {
      this.fetchPartnerResources.clearCache();
      this.topics.next([]);
      this.categories.next([]);
      this.providers.next([]);
      this.orgResources.next(null);
    });
  }


  fetchAllTopics = cacheResponse2(() => {
    const url = environment.resourceReadApiHost + '/topics/active';
    return this.http.get(url)
      .pipe(
        tap((topics: any) => {
          this.topics.next(topics);
          this.topicDictionary = indexBy(topics, 'topicID');
        }),
        catchError(this.errorHandlingService.handleHttpError),
      );
  });

  fetchAllTopicsWithResCount = cacheResponse2(() => {
    const url = environment.resourceReadApiHost + '/topicsWithResCount' + this.thirdPartyOnlyParams;
    return this.http.get(url).pipe(catchError(this.errorHandlingService.handleHttpError)) as Observable<any>;
  });


  updatePartnerResource(resource: IPublicPartnerContent) {
    return this.http
      .put(environment.accountServiceEndpoint + '/orgs/' + localStorage.getItem('orgID') + '/orgResources/' + resource.resourceID + '/publicPartnerContent',
        resource)
      .pipe(
        tap(() => {
        }),
        catchError(this.errorHandlingService.handleHttpError),
      );
  }


  processGuidelinesByGroup(guidelines: any[], guideDic: any): IGuidelinesByGroup[] {
    // guideID to full guide
    const guides = _.map(guidelines, (g: any) => guideDic[g]).filter(g => !!g);

    // Group guides by provider
    const grouped = _.groupBy(guides, 'provider');
    const guidesByProvider = [];

    Object.keys(grouped).forEach(key => {
      // We don't have provider URL, so extract url from individual standard.
      const url = grouped[key].find((standard: any) => !!standard.url) ? grouped[key].find((standard: any) => !!standard.url)['url'] : '';

      const standards = sortBy((grouped[key].filter((g: any) => g.guidelineType === 'standard') || []), 'name');
      const outcomes = sortBy(([...(grouped[key].filter((g: any) => g.guidelineType === 'outcome' && g.parentGuidelineID) || [])]), 'name');
      let sortedGuilines: any[] = [];
      standards.forEach((s: any) => {
        sortedGuilines.push(s);
        sortedGuilines.push(outcomes.filter(g => g.parentGuidelineID === s.guidelineID));
      });
      guidesByProvider.push({
        provider: key,
        standards: flatten(sortedGuilines), // sort guides by name and outcomes.
        url: url
      });
    });
    return guidesByProvider.filter(g => !!g).sort((a, b) =>
      (a.provider > b.provider) ? 1 : ((b.provider > a.provider) ? -1 : 0)
    );
  }


  processResource(resource: any): any {
    resource.routerLink = this.getResourceLink(resource);
    switch (resource.activityType) {
      case 'onlineResource':
        // resource.routerLink = resource.aliasObject ? environment.frontendDomain + '/cpd/lecture/' + resource.aliasObject.alias : environment.frontendDomain + '/cpd/lectures/' + resource.resourceID;
        resource.coverImageURL = resource.nursingLectureDetail ? resource.nursingLectureDetail.coverImageURL : resource.coverImageURL;
        resource.overview = resource.nursingLectureDetail.description;
        resource.guidelines = resource.nursingLectureDetail.guidelines;
        resource.publishDate = resource.nursingLectureDetail.publishDate;
        // resource.scormUrl = environment.scormEndpoint + '?lecture=' + resource.resourceID + '&orgCode=' + localStorage.getItem('orgcode');
        break;
      case 'blog':
        // resource.routerLink = resource.aliasObject ? environment.frontendDomain + '/cpd/articles/' + resource.aliasObject.alias : environment.frontendDomain + '/cpd/articles/' + resource.resourceID;
        resource.coverImageURL = resource.blogDetail ? resource.blogDetail.thumbnailImages[0] : resource.coverImageURL;
        resource.overview = resource.blogDetail.excerpt;
        resource.guidelines = resource.blogDetail.guidelines;
        resource.publishDate = resource.blogDetail.publishDate;
        // resource.scormUrl = environment.scormEndpoint + '?article=' + resource.resourceID + '&orgCode=' + localStorage.getItem('orgcode');
        break;
      case 'OnlineCourse':
        // resource.routerLink = resource.aliasObject ? environment.frontendDomain + '/cpd/courses/' + resource.aliasObject.alias : environment.frontendDomain + '/cpd/courses/' + resource.resourceID;
        resource.coverImageURL = resource.courseDetail ? resource.courseDetail.coverImageURL : resource.coverImageURL;
        resource.overview = resource.courseDetail.overview;
        resource.guidelines = resource.courseDetail.guidelines;
        resource.publishDate = resource.courseDetail.publishDate;
        // resource.scormUrl = environment.scormEndpoint + '?course=' + resource.resourceID + '&orgCode=' + localStorage.getItem('orgcode');
        break;
      case 'explainer':
        // resource.routerLink = resource.aliasObject ? environment.frontendDomain + '/cpd/explainers/' + resource.aliasObject.alias : environment.frontendDomain + '/cpd/explainers/' + resource.resourceID;
        resource.coverImageURL = resource.explainerDetail ? resource.explainerDetail.coverImageURL : resource.coverImageURL;
        resource.overview = resource.explainerDetail.overview;
        resource.guidelines = resource.explainerDetail.guidelines;
        resource.publishDate = resource.explainerDetail.publishDate;
        // resource.scormUrl = environment.scormEndpoint + '?explainer=' + resource.resourceID + '&orgCode=' + localStorage.getItem('orgcode');
        break;
      case 'podcast':
        // resource.routerLink = environment.frontendDomain + '/cpd/podcast' + resource.resourceID;
        resource.coverImageURL = 'https://i1.sndcdn.com/artworks-000181175362-9axkly-t200x200.jpg';
        break;
      default:
        // BYO
        resource.coverImageURL = resource.coverImageUrl;
        if (resource.orgID) {
          // Resource created by new Resource Builder
          if (resource.resourceType) {
            resource.routerLink = this.getByoResourceLink(resource);
          } else {
            // Old BYO, back comparability
            resource.routerLink = resource.website ? resource.website : (resource.videoUrl ? resource.videoUrl : '');
          }
          resource.outcomes = resource.orgResourceDetail.outcomes || [];
          resource.overview = resource.description;
          resource.publishDate = resource.orgResourceDetail.publishDate || '';
          resource.availableDate = resource.orgResourceDetail.revisedDate || '';
        }
    }
    return resource;
  }

  getResourceLink(resource): string {
    let link = '';
    // BYO resources
    if (resource.orgID) {
      link = this.getByoResourceLink(resource);
    }
    // Ausmed & 3rd party resources
    else if (resource.activityType) {
      switch (resource.activityType) {
        case 'onlineResource':
          link = resource.aliasObject ? environment.frontendDomain + '/cpd/lecture/' + resource.aliasObject.alias
            : resource.alias ? environment.frontendDomain + '/cpd/lecture/' + resource.alias
              : environment.frontendDomain + '/cpd/lectures/' + resource.resourceID;
          break;
        case 'blog':
          link = resource.aliasObject ? environment.frontendDomain + '/cpd/articles/' + resource.aliasObject.alias : environment.frontendDomain + '/cpd/articles/' + resource.resourceID;
          break;
        case 'OnlineCourse':
          link = resource.aliasObject ? environment.frontendDomain + '/cpd/courses/' + resource.aliasObject.alias : environment.frontendDomain + '/cpd/courses/' + resource.resourceID;
          // console.trace('OnlineCourse', resource, link);
          break;
        case 'explainer':
          link = resource.aliasObject ? environment.frontendDomain + '/cpd/explainers/' + resource.aliasObject.alias : environment.frontendDomain + '/cpd/explainers/' + resource.resourceID;
          break;
        case 'podcast':
          link = environment.frontendDomain + '/cpd/podcast/' + resource.resourceID;
          break;
        case 'guideToPractice':
          link = environment.frontendDomain + '/cpd/guides/' + resource.resourceID;
          break;
        default:
          // 3rd party resource
          link = resource.website;
          // console.log('3rd party', resource, link);
          break;
      }
    }
    return link;
  }

  getByoResourceLink(resource: IOrgResource): string {
    const resourceType = getByoResourceTypeByResource(resource).value;
    if (resourceType === RESOURCE_TYPE_ENUM.article || resourceType === RESOURCE_TYPE_ENUM.onlineCourse ||
      resourceType === RESOURCE_TYPE_ENUM.video || resourceType === RESOURCE_TYPE_ENUM.practicalActivity || resourceType === RESOURCE_TYPE_ENUM.form) {
      let middle;
      switch (resourceType) {
        case RESOURCE_TYPE_ENUM.article:
          middle = 'articles';
          break;
        case RESOURCE_TYPE_ENUM.onlineCourse:
          middle = 'courses';
          break;
        case RESOURCE_TYPE_ENUM.video:
          middle = 'lectures';
          break;
        case RESOURCE_TYPE_ENUM.practicalActivity:
          middle = 'practicals';
          break;
        case RESOURCE_TYPE_ENUM.form:
          middle = 'surveys';
          break;
      }
      if (this.organisationService.isContractType(PRODUCT_TYPES.HIPPOTLIAN) && resource.orgID) {
        return `${environment.frontendDomain}/learning/${middle}/${resource.resourceID}`;
      } else if (resource.orgID) {
        const orgID = resource?.orgResourceDetail?.isShareable ? localStorage.getItem('orgID') : resource.orgID;
        return `${environment.frontendDomain}/cpd/org/${orgID}/${middle}/${resource.resourceID}`;
      }
    } else if (resourceType === RESOURCE_TYPE_ENUM.file || resourceType === RESOURCE_TYPE_ENUM.weblink) {
      return resource.website;
    } else {
      return '';
    }
  }

  getResourceStatus(resource) {
    switch (resource.activityType) {
      case 'blog':
        return resource.blogDetail.status;
      case 'explainer':
        return resource.explainerDetail.status;
      case 'OnlineCourse':
        return resource.courseDetail.status;
      case 'onlineResource':
        return resource.nursingLectureDetail.status;
      case 'guideToPractice':
        return resource.guideToPracticeDetail.status;
    }
  }

  getResourceScormURL(resource): string {
    return `${environment.scormEndpoint}/?${this.resourceDict[resource.resource.activityType]?.toLowerCase()}=${resource.resource.resourceID}&domainID=${resource.domainID}&orgCode=${localStorage.getItem('orgcode')}&orgID=${localStorage.getItem('orgID')}`;
  }

  // not in use; use in fetchThirdPartyProviders instead
  getThirdPartyProviders() {
    if (this.providerStore.length < 1) {
      this.fetchThirdPartyProvidersWithResCount().subscribe();
    }
    return this.providers.asObservable();
  }

  getCategories() {
    if (this.categoryStore.length < 1) {
      this.fetchCategories().subscribe();
    }
    return this.categories.asObservable();
  }


  getThirdPartyResourceByID(resourceID) {
    return this.http
      .get(environment.resourceReadApiHost + '/thirdPartyResources/' + resourceID)
      .pipe(catchError(this.errorHandlingService.handleHttpError))
      .toPromise();
  }

  // !!!Reminder: This api only works for ausmed resources and yet to support 3rd Party and orgResource
  getResourceByIDs(resourceIDs: string[]): Observable<any> {
    return this.http.post(`${environment.resourceReadApiHost}/resources/getByIDs`, { resourceIDs: resourceIDs })
      .pipe(catchError(this.errorHandlingService.handleHttpError));
  }

  fetchThirdPartyResourceByID(resourceID) {
    return this.http
      .get(environment.resourceReadApiHost + '/thirdPartyResources/' + resourceID)
      .pipe(catchError(this.errorHandlingService.handleHttpError));
  }


  getResourceByAlias(type: string, alias: string): Observable<any> {
    return this.http.get(`${environment.resourceReadApiHost}/resources/alias?resourceType=${type}&alias=${alias}`)
      .pipe(catchError(this.errorHandlingService.handleHttpError));
  }


  fetchResourceByID(resourceID: string): Observable<any> {
    return this.http.get(`${environment.resourceReadApiHost}/resources/${resourceID}?status=all`)
      .pipe(catchError(this.errorHandlingService.handleHttpError));
  }

  getResourceFeedback(orgID: string, resourceID: string): Observable<any> {
    return this.http.get(`${environment.resourceReadApiHost}/comments/org/${orgID}/resource/${resourceID}`)
      .pipe(catchError(this.errorHandlingService.handleHttpError));
  }

  uploadFile(file, orgID, options?): Observable<any> {
    return this.http.post(environment.uploadEndpoint + `/orgs/${orgID}/files`, file, options)
      .pipe(
        timeout(60000),
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  uploadLogo(logo, orgID) {
    return this.http.post(environment.uploadEndpoint + `/orgs/${orgID}/logo`, logo)
      .pipe(
        timeout(60000),
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  getCategoryByAlias(alias): Observable<any> {
    return this.http
      .get<any>(environment.resourceReadApiHost + '/categories/alias?alias=' + alias)
      .pipe(catchError(this.errorHandlingService.handleHttpError));
  }

  getAllGuidelines(): Observable<any> {
    const url = `${environment.resourceReadApiHost}/guidelines/active`;
    return this.http.get(url)
      .pipe(
        map((guidelines: any) => flatten(values(mapObject(groupBy(guidelines, 'provider'), (val) => sortBy(val, 'name'))))),
        tap((guidelines: any) => {
          const standards = guidelines.filter((g: any) => g.guidelineType === 'standard') || [];
          const outcomes = [...(guidelines.filter((g: any) => g.guidelineType === 'outcome' && g.parentGuidelineID) || [])].map((outcome: any) => {
            outcome.parentGuideline = standards.find((s: any) => s.guidelineID === outcome.parentGuidelineID);
            return outcome;
          });
          let sortedGuilines: any[] = [];
          standards.forEach((s: any) => {
            sortedGuilines.push(s);
            sortedGuilines.push(outcomes.filter(g => g.parentGuidelineID === s.guidelineID));
          });
          this.guidelines.next(sortBy(flatten(sortedGuilines), 'providerID', 'name'));
        }),
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  public fetchCachedGuidelines(refresh?: boolean): Observable<any> {
    if (refresh || (!this.guidelines.value && !this.pendingGuidelines)) {
      this.pendingGuidelines = true;
      const fetch$ = this.getAllGuidelines().pipe(
        tap(() => this.pendingGuidelines = false),
        tap(guidelines => this.guidelineDict = indexBy(guidelines, 'guidelineID')),
        catchError(this.errorHandlingService.handleHttpError),
        share(),
      );
      fetch$.subscribe(); // makes observable hot (important so as not to cause pending bugs)
    }
    return this.guidelines.pipe(
      tap(guidelines => this.guidelineDict = indexBy(guidelines, 'guidelineID')),
      tap(guidelines => this.guidelineDictByProvider = groupBy(guidelines, 'provider')),
      filter(x => !!x),
    ); // emit pending / cached value (wihout null and only once)
  }

  isEnabledGuideline<T extends { provider: FRAMEWORKS_ENUM }>(guideline: T, enabledFrameworks?: FRAMEWORKS_ENUM[]): boolean {
    if (!enabledFrameworks) {
      enabledFrameworks = this.organisationService.getEnabledFrameworks();
    }
    return includes(enabledFrameworks, guideline.provider);
  }

  /**
   * filter enabled guidelines from passed in guidelines
   * @param guidelines
   * @param optional: org enabledFrameworks
   */
  getEnabledGuidelines<T extends { provider: FRAMEWORKS_ENUM }>(guidelines: T[], enabledFrameworks?: FRAMEWORKS_ENUM[]): T[] {
    if (!enabledFrameworks) {
      enabledFrameworks = this.organisationService.getEnabledFrameworks();
    }
    return guidelines.filter(guideline => this.isEnabledGuideline(guideline, enabledFrameworks));
  }

  isEnabledFramework(framework: FRAMEWORKS_ENUM, enabledFrameworks: FRAMEWORKS_ENUM[]): boolean {
    return includes(enabledFrameworks, framework);
  }

  filterGuidelines(guidelines: string[]): any[] {
    return guidelines.map(g => {
      return this.guidelineDict[g];
    }).filter(f => this.isEnabledGuideline(f));
  }

  searchAwsResource(searchText: string) {
    return this.http
      .get(environment.awsCloudSearch + '?q=' + searchText + '&q.parser=lucene&size=50')
      .toPromise();
  }

  /**
   *
   * add isNew, isUpdated
   */
  getResourceWithBadges(resource) {
    let isNew,
      isUpdated;
    const updatedDateDiff = Math.ceil((new Date().getTime() - new Date(resource.availableDate).getTime()) / (1000 * 3600 * 24));
    isUpdated = (updatedDateDiff < 7);
    const publishDateDiff = Math.ceil((new Date().getTime() - new Date(resource.publishDate).getTime()) / (1000 * 3600 * 24));
    isNew = (publishDateDiff < 7);
    return { ...resource, isNew, isUpdated };
  }

  /**
   * flatten resourceDetail for Ausmed Resources
   */
  expandResourceDetail(resource): any {
    return ({
      ...resource,
      ...resource['courseDetail'],
      ...resource['blogDetail'],
      ...resource['explainerDetail'],
      ...resource['guideToPracticeDetail'],
      ...resource['nursingLectureDetail'],
    });
  }

  /**
   * isNew first, then isUpdated
   * @param resources
   */
  sortResourceByBadges(resources: any[]) {
    const sortedNewResources = sortBy(resources.filter(r => r.isNew), r => new Date(r.publishDate)),
      sortedUpdatedResources = sortBy(resources.filter(r => !r.isNew && r.isUpdated), r => new Date(r.availableDate)),
      otherResources = sortBy(resources.filter(r => !r.isNew && !r.isUpdated), r => new Date(r.availableDate)),
      sortedResources = [...sortedNewResources, ...sortedUpdatedResources, ...otherResources];
    return sortedResources;
  }

  /********************* BYO resource start *********************/




  fetchCourseByID(resourceID): Observable<IOrgResource> {
    return this.http
      .get<IOrgResource>(environment.resourceReadApiHost + '/orgs/' + localStorage.getItem('orgID') + '/orgResources/' + resourceID + '/full')
      .pipe(
        tap(res => this.orgCourse.next(res)),
        catchError(error => {
          const parsedError = this.errorHandlingService.handleHttpError(error);
          this.orgCourse.error(error);
          return parsedError;
        }),
      );
  }

  searchResource(keywords, filter = {}, condition = 'and'): Observable<any[]> {
    this.thirdPartyOnlyParams ? filter['thirdPartyOnly'] = true : null;
    return this.http
      .post<any[]>(environment.resourceReadApiHost + '/resources/search?_page=1&_limit=10000&condition=' + condition + '&title_like=' + keywords, filter)
      .pipe(
        catchError(error => {
          const parsedError = this.errorHandlingService.handleHttpError(error);
          return parsedError;
        }),
      );
  }

  fetchResources(page = 1, limit = 8): Observable<any[]> {
    return this.http
      .post<any[]>(environment.resourceReadApiHost + `/resources/search?_page=${page}&_limit=${limit}`, {})
      .pipe(
        // tap(() => console.log('fetch')),
        shareReplay(1),
        catchError(this.customErrorHandler.handleHttpError),
      );
  }

  // For course builder side bar
  fetchCachedCourseByID(resourceID: string): Observable<IOrgResource> {
    if (!this.pendingOrgCourse && !this.orgCourse.value) {
      this.pendingOrgCourse = true;
      const fetch$ = this.fetchCourseByID(resourceID).pipe(
        tap(() => this.pendingOrgCourse = false),
        catchError((e) => {
          this.pendingOrgCourse = false;
          return e;
        }),
        share(),
      );
      fetch$.subscribe();
    }
    return this.orgCourse.pipe(
      filter(r => !!r),
      catchError(error => this.errorHandlingService.handleHttpError(error))
    );
  }

  getLinkToResourceDetailPageByResource(resource): string {
    if (resource.source && resource.source !== 'ausmed' && resource.source !== 'organisation') {
      return `/pages/library/provider/${resource.providerName}/resource/${resource.resourceID}`;
    } else if (resource.source && resource.source === 'organisation') {
      return `/pages/staff-portal/byo/item/${resource.resourceID}`;
    } else {
      if (resource.providerType) {
        switch (resource.providerType) {
          case 'Internal':
            if (resource.resourceType === 'onlineCourse') {
              return `/pages/staff-portal/byo/course-analytics/${resource.resourceID}`;
            } else {
              return `/pages/staff-portal/byo/item/${resource.resourceID}`;
            }
          case '3rd Party':
            return `/pages/library/provider/${resource.providerName}/resource/${resource.resourceID}`;
          default:
            return `/pages/library/resource/${resource.resourceID}`;
        }
      } else {
        return `/pages/library/resource/${resource.resourceID}`;
      }
    }
  }

  clearCourse() {
    this.orgCourse.next(null);
  }

  /********************* BYO resource end *********************/

  /********************* resource feed start *********************/


  // getLatestResource() {
  //   return of(this.latestResources.value);
  // }

  /********************* resource feed end *********************/

  sendReminders(orgID: string, resourceID: string) {
    return this.http.get(`${environment.accountServiceEndpoint}/orgs/${orgID}/resources/${resourceID}/assignTracks/remind`).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  public fetchCachedTopics(refresh?: boolean): Observable<any> {
    if (refresh || (!this.topics.value && !this.pendingTopics)) {
      this.pendingTopics = true;
      const fetch$ = this.fetchAllTopics().pipe(
        tap(() => this.pendingTopics = false),
        catchError(this.errorHandlingService.handleHttpError),
        share(),
      );
      fetch$.subscribe(); // makes observable hot (important so as not to cause pending bugs)
    }
    return this.topics.pipe(filter(x => !!x)); // emit pending / cached value (wihout null and only once)
  }

  fetchAllBundles(): Observable<Bundle[]> {
    const orgID = localStorage.getItem('orgID');
    const url = `${environment.resourceReadApiHost}/bundles/owner`;
    return this.http.post<Bundle[]>(url, {
      owners: ['Ausmed', 'Library'],
      publishedOnly: true,
      withResources: true
    }).pipe(
      map(bundles => bundles.filter(b => b.status === 'Published' && b.resources && b.resources.length > 0)),
      catchError(this.errorHandlingService.handleHttpError),
      tap(bundles => this.allBundles.next(bundles))
    );
  }

  fetchCachedBundles(refresh = false): Observable<Bundle[]> {
    if (refresh || (!this.allBundles.value && !this.pendingBundles)) {
      this.pendingBundles = true;
      const fetch$ = this.fetchAllBundles().pipe(
        tap(() => this.pendingBundles = false),
        catchError((e) => {
          this.pendingBundles = false;
          return throwError(e);
        }),
        share(),
      );
      fetch$.subscribe();
    }
    return this.allBundles.pipe(filter(x => !!x));

  }

  isResourceLocked(resource): Observable<boolean> {
    if (resource?.thirdParty === true || (resource?.providerName || '').toLowerCase().indexOf('ausmed') < 0) {
      return of(false);
    } else {
      if (!this.purchasedResourceIDs) {
        return this.fetchCachedBundles().pipe(
          mergeMap(bundles => {
            const contract = this.organisationService.plainActiveContract;
            let resourceIDs = [];
            if (contract?.metaData?.bundles) {
              const purchasedBundles = bundles.filter(b => contains(contract?.metaData?.bundles, b.bundleID));
              resourceIDs = flatten(purchasedBundles.map(b => (b.resources || []).map((r: any) => r.resourceID)));
            }
            if (contract?.metaData?.purchasedResources) {
              resourceIDs = resourceIDs.concat(contract?.metaData?.purchasedResources)
            }
            this.purchasedResourceIDs = resourceIDs;
            return of(!contains(this.purchasedResourceIDs, resource.resourceID))
          })
        )
      } else {
        return of(!contains(this.purchasedResourceIDs, resource.resourceID));
      }
    }

  }

  getPurchasedResourcesIDs(): Observable<string[]> {
    if (this.purchasedResourceIDs === undefined) {
      const thirdPartyResources$ = this.isAusmedLMSFreeUser ? of([]) : this.fetchThirdPartyResources();
      return combineLatest([this.fetchCachedBundles(), thirdPartyResources$]).pipe(
        mergeMap(([bundles, thirdPartyResources]: any) => {
          console.log(thirdPartyResources)
          const contract = this.organisationService.plainActiveContract;
          let resourceIDs = [];
          if (contract?.metaData?.bundles) {
            const purchasedBundles = bundles.filter(b => contains(contract?.metaData?.bundles, b.bundleID));
            resourceIDs = flatten(purchasedBundles.map(b => (b.resources || []).map((r: any) => r.resourceID)));
          }
          if (contract?.metaData?.purchasedResources) {
            resourceIDs = resourceIDs.concat(contract?.metaData?.purchasedResources)
          }
          this.purchasedResourceIDs = resourceIDs.concat(thirdPartyResources.map(r => r.resourceID).filter(id => !!id));
          return of(this.purchasedResourceIDs);
        })
      )
    } else {
      return of(this.purchasedResourceIDs);
    }
  }

  getPurchasedBundles(): Observable<Bundle[]> {
    return this.fetchCachedBundles().pipe(
      mergeMap(bundles => {
        const contract = this.organisationService.plainActiveContract;
        if (contract?.metaData?.bundles) {
          return of(bundles.filter(b => contains(contract?.metaData?.bundles, b.bundleID)));

        }
        return of([])
      })
    )
  }

  getAllResourceGrouped() {
    const ausmedResources$ = this.isAusmedLMSFreeUser ? of([]) : this.fetchAusmedResources().pipe(take(1), map((resources: any[]) => sortBy(resources, 'title').map((r) => {
      r.providerType = 'Ausmed';
      r.group = 'Ausmed';
      return r;
    })));
    const thirdPartyResources$ = this.isAusmedLMSFreeUser ? of([]) : this.fetchThirdPartyResources().pipe(take(1), map((thirdPartyResources: any[]) => sortBy(thirdPartyResources, 'title').map((r) => {
      r.providerType = '3rd Party';
      r.group = r.providerName;
      return r;
    })));
    const orgResources$ = this.orgResourceService.fetchCachedOrgActiveResources().pipe(take(1), map((orgResources: any[]) => sortBy(orgResources, 'title').map((r) => {
      r.providerType = 'Internal';
      r.group = 'Internal';
      return r;
    })));
    const partnerResources$ = this.isAusmedLMSFreeUser ? of([]) : this.fetchPartnerResources().pipe(take(1), map((thirdPartyResources: any[]) => sortBy(thirdPartyResources, 'title').map((r) => {
      r.providerType = 'Internal';
      r.group = 'Partner';
      return r;
    })));
    return combineLatest([ausmedResources$, thirdPartyResources$, orgResources$, partnerResources$]).pipe(
      map(([ausmedResources, thirdPartyResources, orgResources, partnerResources]: any) => orgResources.concat(ausmedResources, thirdPartyResources, partnerResources))
    )

  }
}

export interface IFeedback {
  feedbackID: string;
  orgID: string;
  resourceID: string;
  provider: string;
  userID: string;
  fullName: string;
  isPublic: boolean;
  contentFeedback: string;
  recommendScale: number;
  createDate: string;
}

export interface IGuideline {
  guidelineID: string;
  name: string;
  status?: string;
  provider: FRAMEWORKS_ENUM;
  url?: string;
  description: string;
  categories?: string[];
  availableDate?: string;
  expiryDate?: string;
  createDate: string;
  updateDate: string;
  guidelineType?: string;
  parentGuidelineID?: string;
  providerID?: string;
  parentGuideline?: IGuideline;
}

export interface IGuidelinesByGroup {
  provider: FRAMEWORKS_ENUM;
  standards: IGuideline[];
  url: string;
}

export interface IProfessionWithResCount {
  name: string;
  resCount: number;
}

export interface ICategoryWithResCount {
  name: string;
  resCount: number;
  alias: string;
  coverImageURL: string;
}

export interface IProvider {
  name: string;
  alias?: string;
  description?: string;
  website?: string;
  logoURL?: string;
  providerType?: string;
  isContentPartner: boolean;
  isGuidelinePartner: boolean;
  isActive: boolean;
  subHeadings?: string[];
  resCount?: number;
}

export const PROFESSIONS = [
  'Registered Nurse',
  'Registered Midwife',
  'Enrolled Nurse',
  'Physiotherapist',
  'Occupational Therapist',
  'Pharmacist',
  'Chiropractor',
  'Osteopath',
  'Nurse Practitioner',
  'Chinese Medicine Practitioner',
  'Paramedic',
  'Psychologist',
  'Care Worker',
];

export const PROFESSION_MAP_DICT = {
  'Care Worker': [
    'Community Care Worker', 'Health Care Worker',
    'Personal Care Assistant (PCA)', 'Assistant in Nursing (AIN)', 'Direct Care Worker',
    'Allied Health Assistant', 'Ward Assistant'
  ],
};

export interface IPublicPartnerContent {
  orgID: string;
  resourceID: string;
  isPublic?: boolean;
}

export interface Bundle {
  bundleID: string;
  name: string;
  orgID?: string;
  owner?: string; // Org, Ausmed, Library
  status?: string;
  description?: string;
  resources?: [];
  coverImageURL?: string;
}

export interface ICachedResource {
  title;
  providerName?;
  orgResource?;
  min;
  resourceType?;
  topics?: string[];
  resourceID: string;
}

// export const PROFESSIONS = [
//   {
//     name: 'Registered Nurse',
//     url: 'registered-nurse'
//   },
//   {
//     name: 'Registered Midwife',
//     url: 'registered-midwife'
//   },
//   {
//     name: 'Enrolled Nurse',
//     url: 'enrolled-nurse'
//   },
//   {
//     name: 'Physiotherapist',
//     url: 'physiotherapist'
//   },
//   {
//     name: 'Occupational Therapist',
//     url: 'occupational-therapist'
//   },
//   {
//     name: 'Pharmacist',
//     url: 'pharmacist'
//   },
//   {
//     name: 'Chiropractor',
//     url: 'chiropractor'
//   },
//   {
//     name: 'Osteopath',
//     url: 'osteopath'
//   },
//   {
//     name: 'Nurse Practitioner',
//     url: 'nurse-practitioner'
//   },
//   {
//     name: 'Chinese Medicine Practitioner',
//     url: 'chinese-medicine-practitioner'
//   },
//   {
//     name: 'Paramedic',
//     url: 'paramedic'
//   },
//   {
//     name: 'Psychologist',
//     url: 'psychologist'
//   },
//   {
//     name: 'Care Worker',
//     url: 'care-worker'
//   }
// ];




