<label class="f-small text-neutral-800 mb-1" *ngIf="showLabel">{{ label || 'Date'}}</label>
<form class="d-flex">
    <div class="form-group hidden">
        <div class="input-group">
            <input [positionTarget]="buttonEl" name="datepicker" class="form-control f-small d-none" ngbDatepicker
                #datepicker="ngbDatepicker" [autoClose]="'outside'" (dateSelect)="onDateSelection($event);" [placement]="'bottom'"
                [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden" [startDate]="fromDate!" container="body"
                [maxDate]="maxDate" [minDate]="minDate" [footerTemplate]="footerTemplate"/>
            <ng-template #t let-date let-focused="focused" let-disabled="disabled">
                <span class="custom-day" [class.focused]="focused" [class.text-muted]="disabled" [class.range]="isRange(date)"
                    [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                    (mouseleave)="hoveredDate = null">
                    {{ date.day }}
                </span>
            </ng-template>
        </div>
    </div>
    <div>
        <button #buttonEl [class]="buttonClass?buttonClass:'btn border-neutral-500'" id="date-picker"
            (click)="datepicker.toggle()" type="button">{{buttonText?buttonText:getDateRangeValue(fromDate, toDate)}}
        </button>
    </div>
</form>
<div>
    <small class="text-error-input" *ngIf="submitted && (!fromDate || !toDate)">
        Please select a Date.
    </small>
</div>

<ng-template #footerTemplate>
    <p *ngIf="oneMonthRange" class="pb-2 ps-2 fw-semibold f-small">Select up to 31 Days</p>
</ng-template>