import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

import { NgIf } from '@angular/common';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';

// import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';


@Component({
  selector: 'app-tooltip',
  template: `
      <ng-template #list>
          <div class="d-flex flex-column">
              <p class="text-white f-small" [innerHtml]="text"></p>
              <p *ngIf="secondaryText" class="text-white f-small">{{secondaryText}}</p>
          </div>
      </ng-template>

      <ui-streamline-icon #t="ngbTooltip" (mouseenter)="triggers==='manual'?t.open():null"
              *ngIf="icon==='info'" [ngbTooltip]="list" [placement]="placement" [tooltipClass]="tooltipClass"
              [triggers]="triggers" container="body" 
              [slIcon]="{name: 'interface-alert-information-circle', theme: 'micro-bold',color: 'fill-default', svgStyle: {'padding-bottom': '2px'}}"
      ></ui-streamline-icon>

      <ui-streamline-icon #t="ngbTooltip" (mouseenter)="triggers==='manual'?t.open():null"
              *ngIf="icon==='danger'"
              [ngbTooltip]="list" [placement]="placement" [tooltipClass]="tooltipClass" [triggers]="triggers"
              container="body"
              [slIcon]="{name: 'interface-alert-warning-circle', theme: 'micro-bold', color: 'fill-danger'}">
      </ui-streamline-icon>

      <ui-streamline-icon #t="ngbTooltip" (mouseenter)="triggers==='manual'?t.open():null"
              *ngIf="icon==='warning'"
              [ngbTooltip]="list" [placement]="placement" [tooltipClass]="tooltipClass" [triggers]="triggers"
              container="body"
              [slIcon]="{name: 'interface-alert-warning-circle', theme: 'micro-bold', color: 'fill-warning'}">
      </ui-streamline-icon>
  `,
  encapsulation: ViewEncapsulation.None,
  // All the custom css must be pre defined here.
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    NgbTooltip,
    UIStreamlineIconComponent
  ],
})
export class TooltipComponent implements OnInit {
  @Input() text: string | TemplateRef<any>;
  @Input() secondaryText?: string;
  @Input() tooltipClass = 'custom-tooltip';
  @Input() placement = 'top auto';
  @Input() triggers = 'hover focus';
  @Input() icon: 'danger' | 'info' | 'warning' = 'info';

  constructor() {
  }

  ngOnInit(): void {
  }

}
