import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {LocalTableUniqKey} from './localTableUniqKey';
import {sortBy, isEqual, pluck} from 'underscore';
import {ICacheTableSettings} from './LocalEditedColumnsByKey';
import {removeEmptyFields} from '../../utils/remove-empty-fields';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { moveItemInArray, CdkDropList, CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { getCachedSettings } from '@components/smart-table/smart-table.component';
import { FormsModule } from '@angular/forms';
import { InputGroupComponent } from '../../components/input-group/input-group.component';
import { NgFor, NgClass, NgIf } from '@angular/common';
import { ModalSingleComponent } from '../../components/modal-single/modal-single.component';

interface IColumnsConfig {
  [key: string]: {
    title: string;
    exemptFromEdit?: boolean;
    isSticky?: boolean;
    [key: string]: any;
  };
}

/* Originally Created by @Yipin: If you want to use this modal, please pass in the *columns* property of table settings.
 * Please note that it is mandatory to pass in the reference of columns object (mutable)
 * Columns with `exemptFromEdit: true` will be ignored in the modal!
 * */
@Component({
    selector: 'app-shared-edit-table-column-modal',
    templateUrl: './shared-edit-table-column-modal.component.html',
    styleUrls: ['./shared-edit-table-column-modal.component.scss'],
    standalone: true,
    imports: [
        ModalSingleComponent,
        CdkDropList,
        NgFor,
        CdkDrag,
        CdkDragHandle,
        NgClass,
        InputGroupComponent,
        FormsModule,
        NgIf,
    ],
})
export class SharedEditTableColumnModalComponent implements OnInit {
  /*  Inputs here have no functions but a reminder what developers need to pass in  */
  @Input() readonly localCacheKey: LocalTableUniqKey;
  @Input() tableColumnsConfig: IColumnsConfig;
  @Input() title = 'Edit Columns';
  @Input() confirmText = 'Save';
  @Input() hideSortColumn = false;
  private _originalColumnConfig: IColumnsConfig;
  private _columnTitleToNameMap: Map<string, string>;
  // enabled: whether show the input and lable; disabled: set input to disabled status
  titlesEnableStatus: { enabled: boolean; title: string, disabled: boolean }[] = [];

  constructor(private activeModal: NgbActiveModal, private broadcastService: BroadcastService) {
  }

  ngOnInit() {
    const columnTitleToNameMap = new Map();
    this._originalColumnConfig = this.tableColumnsConfig;
    let orderedColumnNames = Object.keys(this.tableColumnsConfig);
    const cachedColumnOrder = getCachedSettings(this.localCacheKey)?.orderedColumns;

    // use cached order
    if (cachedColumnOrder && cachedColumnOrder.length === orderedColumnNames.length
      && orderedColumnNames.every(c => cachedColumnOrder.includes(c))) {
        orderedColumnNames = cachedColumnOrder;
    }

    const editableColumnTitles = orderedColumnNames.filter(columnPropertyName => {
      const columnConfig = Reflect.get(this.tableColumnsConfig, columnPropertyName);
      columnTitleToNameMap.set(columnConfig.title, columnPropertyName);
      return !(columnConfig.exemptFromEdit || columnConfig.isSticky);
    }).map(columnPropertyName => Reflect.get(this.tableColumnsConfig, columnPropertyName).title) as string[];
    this._columnTitleToNameMap = columnTitleToNameMap;
    const enabledColumnNames = (JSON.parse(localStorage.getItem(this.localCacheKey)) as ICacheTableSettings)?.columns
      || Object.keys(this._originalColumnConfig).filter(c => this._originalColumnConfig[c]?.defaultToShow !== false);
    this.titlesEnableStatus = editableColumnTitles.map(title => ({
      title,
      enabled: enabledColumnNames.includes(this._columnTitleToNameMap.get(title)),
      disabled: this.isDisabled(this._columnTitleToNameMap.get(title)),
    }));
  }

  save() {
    const enabledColumnNames = this.titlesEnableStatus.filter(({ enabled }) => enabled).map(({ title }) => this._columnTitleToNameMap.get(title));
    const parsedLocalSettings = JSON.parse(localStorage.getItem(this.localCacheKey)) as ICacheTableSettings;
    let noChange;
    const hideSubHeader = parsedLocalSettings?.hideSubHeader;
    if (Array.isArray(parsedLocalSettings)) {
      // will be removed in the future; to handle legacy version
      noChange = isEqual(sortBy(parsedLocalSettings), sortBy(enabledColumnNames));
    } else {
      const previousColumns = parsedLocalSettings?.columns ? parsedLocalSettings.columns : [];
      noChange = isEqual(sortBy(previousColumns), sortBy(enabledColumnNames));
    }
    const columnOrder = pluck(this.titlesEnableStatus, 'title').map(t => this._columnTitleToNameMap.get(t));
    // add in exempt columns which are missing in the modal
    const columns = this.tableColumnsConfig;
    // add in action column to back
    if (columns.hasOwnProperty('actions')) {
      columnOrder.push('actions');
    }
    // add in exempt/sticky col to front
    const nonReorderableCol = Object.keys(columns).find(el => (columns[el]?.exemptFromEdit || columns[el]?.isSticky) && el !== 'actions')
    if (nonReorderableCol) {
      columnOrder.unshift(nonReorderableCol);
    }

    // console.log(columnOrder);
    const newCacheTableSettings = {columns: enabledColumnNames, hideSubHeader, orderedColumns: columnOrder} as ICacheTableSettings;
    removeEmptyFields(newCacheTableSettings);
    localStorage.setItem(this.localCacheKey, JSON.stringify(newCacheTableSettings));
    this.broadcastService.broadcast('column-settings-updated', {tableKey: this.localCacheKey});
    this.activeModal.close('refresh');
  }

  get noEnabledColumns(): boolean {
    return this.titlesEnableStatus.every(control => !control.enabled);
  }

  isDisabled (columnTitle: string): boolean {
    return this.tableColumnsConfig[columnTitle]?.isDisabled || false;
  }

  cancel() {
    this.activeModal.close();
  }

  drop(res) {
    const {previousIndex, currentIndex } = res;
    const disabledColIdx = this.titlesEnableStatus
      .map((el, i) => ({idx: i, disabled: el.disabled}))
      .filter(el => {
        // console.log(el);
        return el.disabled === true
      })
      .map(el => el.idx);
    // can't move to or from disabled col
    if (disabledColIdx.includes(previousIndex) || disabledColIdx.includes(currentIndex)) {
      return;
    }
    moveItemInArray(this.titlesEnableStatus, previousIndex, currentIndex);
  }
}
