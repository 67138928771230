<tr *ngFor="let row of grid.getRows()" (click)="userSelectRow.emit(row)" (mouseover)="rowHover.emit(row)" class="ng2-smart-row" [className]="rowClassFunction(row)" [ngClass]="{selected: row.isSelected}">
  <td *ngIf="isMultiSelectVisible" class="ng2-smart-actions ng2-smart-action-multiple-select" (click)="multipleSelectRow.emit(row)">
    <input type="checkbox" class="form-control" [ngModel]="row.isSelected">
  </td>
  <td *ngIf="!row.isInEditing && showActionColumnLeft" class="ng2-smart-actions">
    <ng2-st-tbody-custom [grid]="grid" (custom)="custom.emit($event)" [row]="row" [source]="source"></ng2-st-tbody-custom>

    <ng2-st-tbody-edit-delete [grid]="grid"
                              [deleteConfirm]="deleteConfirm"
                              [editConfirm]="editConfirm"
                              (edit)="edit.emit(row)"
                              (delete)="delete.emit(row)"
                              (editRowSelect)="editRowSelect.emit($event)"
                              [row]="row"
                              [source]="source">
    </ng2-st-tbody-edit-delete>
  </td>
   <td *ngIf="row.isInEditing && showActionColumnLeft"  class="ng2-smart-actions">
    <ng2-st-tbody-create-cancel [grid]="grid" [row]="row" [editConfirm]="editConfirm"></ng2-st-tbody-create-cancel>
  </td>
  <td *ngFor="let cell of getVisibleCells(row.cells)">
    <ng2-smart-table-cell [cell]="cell"
                          [grid]="grid"
                          [row]="row"
                          [isNew]="false"
                          [mode]="mode"
                          [editConfirm]="editConfirm"
                          [inputClass]="editInputClass"
                          [isInEditing]="row.isInEditing">
    </ng2-smart-table-cell>
  </td>

  <td *ngIf="row.isInEditing && showActionColumnRight"  class="ng2-smart-actions">
    <ng2-st-tbody-create-cancel [grid]="grid" [row]="row" [editConfirm]="editConfirm"></ng2-st-tbody-create-cancel>
  </td>

  <td *ngIf="!row.isInEditing && showActionColumnRight" class="ng2-smart-actions">
    <ng2-st-tbody-custom [grid]="grid" (custom)="custom.emit($event)" [row]="row" [source]="source"></ng2-st-tbody-custom>

    <ng2-st-tbody-edit-delete [grid]="grid"
                              [deleteConfirm]="deleteConfirm"
                              [editConfirm]="editConfirm"
                              [row]="row"
                              [source]="source"
                              (edit)="edit.emit(row)"
                              (delete)="delete.emit(row)"
                              (editRowSelect)="editRowSelect.emit($event)">
    </ng2-st-tbody-edit-delete>
  </td>
</tr>

<tr *ngIf="grid.getRows().length == 0">
  <td [attr.colspan]="tableColumnsCount">
    {{ noDataMessage }}
  </td>
</tr>
