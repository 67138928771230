import { BehaviorSubject, Observable, Subject, throwError } from "rxjs";
import { catchError, filter, take, tap } from "rxjs/operators";
import { BroadcastService } from "src/app/services/broadcast.service";

export class DataCacher<T> {
    private _cache$ = new BehaviorSubject<T>(null);
    fetch$: Observable<T>;
    private _pending = false;

  constructor(
    fetch$: Observable<T>,
    broadcastService: BroadcastService
  ) {
    this.fetch$ = fetch$;
    broadcastService.on('logout').subscribe(() => this._cache$.next(null))
  }

  fetchCachedData(refresh = false) {
    if (refresh) {
      this._pending = true;
      return this.fetch$.pipe(tap(res => { this._pending = false; this._cache$.next(res) }));
    }
    if ((!this._cache$.value && !this._pending)) {
      this._pending = true;
      const fetch$ = this.fetch$.pipe(
        tap((data) => {
          this._pending = false;
          this._cache$.next(data)
        }),
        catchError((e) => {
          this._pending = false;
          return throwError(e);
        }),
      );
      fetch$.subscribe(); // makes observable hot (important so as not to cause pending bugs)
    }
    return this._cache$.pipe(
      filter(x => !!x),
      // tap(s => {
      //     console.log(s)
      // })
    ); // emit pending / cached value (wihout null and only once)
  }

  update(updateFn: (currentData: T) => T) {
    if (this._cache$.value) {
      this._cache$.next(updateFn(this._cache$.value))
    }
  }
}

